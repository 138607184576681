@charset "UTF-8";
/*!
 *
 * BurgerEditor StyleSheet Style Sample v2.21.0
 *
 */
[data-bgb] h2:not([class]),
[data-bgb] .bgt-title-h2-container {
  position: relative;
  margin-bottom: 30px;
  padding: 10px 20px;
  background: $soft-gray;
  color: white;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.4;
  @include mq(sp) {
    padding: 8px 12px;
    font-size: 17px;
  }

  &:not([class])::before,
  &.bgt-title-h2-container::before {
    position: absolute;
    top: 5px;
    right: 5px;
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: $blue;
    clip-path: polygon(0 0, 100% 100%, 100% 0);
    @include mq(sp) {
      width: 8px;
      height: 8px;
    }
  }
  &:not([class])::after,
  &.bgt-title-h2-container::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    width: calc(100% - 150px);
    height: 100%;
    background: $blue;
    clip-path: polygon(0 0, 100% 0%, calc(100% - 50px) 100%, 0% 100%);
    @include mq(sp) {
      width: calc(100% - 40px);
      clip-path: polygon(0 0, 100% 0%, calc(100% - 30px) 100%, 0% 100%);
    }
  }

  &:not([class]) span,
  h2.bge-title-h2 {
    position: relative;
    z-index: 1;
    padding-right: 160px;
    @include mq(sp) {
      padding-right: 60px;
    }
  }
}

[data-bgb] h2.blue::before,
[data-bgb].blue .bgt-title-h2-container::before,
[data-bgb] h2.blue::after,
[data-bgb].blue .bgt-title-h2-container::after {
  background: $blue;
}
[data-bgb] h2.orange::before,
[data-bgb].orange .bgt-title-h2-container::before,
[data-bgb] h2.orange::after,
[data-bgb].orange .bgt-title-h2-container::after {
  background: $orange;
}
[data-bgb] h2.green::before,
[data-bgb].green .bgt-title-h2-container::before,
[data-bgb] h2.green::after,
[data-bgb].green .bgt-title-h2-container::after {
  background: $green;
}
[data-bgb] h2.purple::before,
[data-bgb].purple .bgt-title-h2-container::before,
[data-bgb] h2.purple::after,
[data-bgb].purple .bgt-title-h2-container::after {
  background: $purple;
}
[data-bgb] h2.red::before,
[data-bgb].red .bgt-title-h2-container::before,
[data-bgb] h2.red::after,
[data-bgb].red .bgt-title-h2-container::after {
  background: $red;
}

[data-bgb] h2:not([class]):first-child,
[data-bgb] h2.bge-title-h2:first-child {
	margin-top: 0;
}

[data-bgb] h3:not([class]),
[data-bgb] .bge-title-h3 {
  margin-bottom: 30px;
  padding-left: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 3px solid $blue;
  background: url(../img/common/icn_title.svg) left top no-repeat;
  background-size: 27px 47px;
  font-size: 23px;
  font-weight: 700;
  @include mq(sp) {
    padding: 5px 0 5px 25px;
    border-width: 2px;
    background-position: left 5px;
    background-size: 17px 29px;
    font-size: 18px;
    line-height: 1.5;
  }
}

[data-bgb] h3.blue,
[data-bgb].blue .bge-title-h3 {
  margin-bottom: 30px;
  padding-left: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 3px solid $blue;
  background: url(../img/common/icn_title.svg) left top no-repeat;
  background-size: 27px 47px;
  font-size: 23px;
  font-weight: 700;
  @include mq(sp) {
    padding: 5px 0 5px 25px;
    border-width: 2px;
    background-position: left 5px;
    background-size: 17px 29px;
    font-size: 18px;
    line-height: 1.5;
  }
}
[data-bgb] h3.orange,
[data-bgb].orange .bge-title-h3 {
  margin-bottom: 30px;
  padding-left: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 3px solid $orange;
  background: url(../img/common/icn_title_or.svg) left top no-repeat;
  background-size: 27px 47px;
  font-size: 23px;
  font-weight: 700;
  @include mq(sp) {
    padding: 5px 0 5px 25px;
    border-width: 2px;
    background-position: left 5px;
    background-size: 17px 29px;
    font-size: 18px;
    line-height: 1.5;
  }
}
[data-bgb] h3.green,
[data-bgb].green .bge-title-h3 {
  margin-bottom: 30px;
  padding-left: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 3px solid $green;
  background: url(../img/common/icn_title_gr.svg) left top no-repeat;
  background-size: 27px 47px;
  font-size: 23px;
  font-weight: 700;
  @include mq(sp) {
    padding: 5px 0 5px 25px;
    border-width: 2px;
    background-position: left 5px;
    background-size: 17px 29px;
    font-size: 18px;
    line-height: 1.5;
  }
}
[data-bgb] h3.purple,
[data-bgb].purple .bge-title-h3 {
  margin-bottom: 30px;
  padding-left: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 3px solid $purple;
  background: url(../img/common/icn_title_pp.svg) left top no-repeat;
  background-size: 27px 47px;
  font-size: 23px;
  font-weight: 700;
  @include mq(sp) {
    padding: 5px 0 5px 25px;
    border-width: 2px;
    background-position: left 5px;
    background-size: 17px 29px;
    font-size: 18px;
    line-height: 1.5;
  }
}
[data-bgb] h3.red,
[data-bgb].red .bge-title-h3 {
  margin-bottom: 30px;
  padding-left: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 3px solid $red;
  background: url(../img/common/icn_title_rd.svg) left top no-repeat;
  background-size: 27px 47px;
  font-size: 23px;
  font-weight: 700;
  @include mq(sp) {
    padding: 5px 0 5px 25px;
    border-width: 2px;
    background-position: left 5px;
    background-size: 17px 29px;
    font-size: 18px;
    line-height: 1.5;
  }
}

[data-bgb] h3:not([class]):first-child,
[data-bgb] h3.bge-title-h3:first-child {
	margin-top: 0;
}
[data-bgb] h4:not([class]) {
	position: relative;
  margin-bottom: 30px;
  background: $light-blue;
  padding: 10px 18px 10px 40px;
  color: $blue;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.4;
  @include mq(sp) {
    padding-left: 36px;
    font-size: 16px;
  }
  &::before {
    position: absolute;
    top: 19px;
    left: 20px;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid $blue;
    border-right: 2px solid $blue;
    transform: rotate(45deg);
    @include mq(sp) {
      top: 17px;
      left: 16px;
    }
  }
}

[data-bgb] h4.blue {
  position: relative;
  margin-bottom: 30px;
  background: $light-blue;
  padding: 10px 18px 10px 40px;
  color: $blue;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.4;
  @include mq(sp) {
    padding-left: 36px;
    font-size: 16px;
  }
  &::before {
    position: absolute;
    top: 19px;
    left: 20px;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid $blue;
    border-right: 2px solid $blue;
    transform: rotate(45deg);
    @include mq(sp) {
      top: 17px;
      left: 16px;
    }
  }
  a {
    color: $blue;
  }
}
[data-bgb] h4.orange {
  position: relative;
  margin-bottom: 30px;
  background: $light-orange;
  padding: 10px 18px 10px 40px;
  color: $orange;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.4;
  @include mq(sp) {
    padding-left: 36px;
    font-size: 16px;
  }
  &::before {
    position: absolute;
    top: 19px;
    left: 20px;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid $orange;
    border-right: 2px solid $orange;
    transform: rotate(45deg);
    @include mq(sp) {
      top: 17px;
      left: 16px;
    }
  }
  a {
    color: $orange;
  }
}
[data-bgb] h4.green {
  position: relative;
  margin-bottom: 30px;
  background: $light-green;
  padding: 10px 18px 10px 40px;
  color: $green;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.4;
  @include mq(sp) {
    padding-left: 36px;
    font-size: 16px;
  }
  &::before {
    position: absolute;
    top: 19px;
    left: 20px;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid $green;
    border-right: 2px solid $green;
    transform: rotate(45deg);
    @include mq(sp) {
      top: 17px;
      left: 16px;
    }
  }
  a {
    color: $green;
  }
}
[data-bgb] h4.purple {
  position: relative;
  margin-bottom: 30px;
  background: $light-purple;
  padding: 10px 18px 10px 40px;
  color: $purple;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.4;
  @include mq(sp) {
    padding-left: 36px;
    font-size: 16px;
  }
  &::before {
    position: absolute;
    top: 19px;
    left: 20px;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid $purple;
    border-right: 2px solid $purple;
    transform: rotate(45deg);
    @include mq(sp) {
      top: 17px;
      left: 16px;
    }
  }
  a {
    color: $purple;
  }
}
[data-bgb] h4.red {
  position: relative;
  margin-bottom: 30px;
  background: $light-red;
  padding: 10px 18px 10px 40px;
  color: $red;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.4;
  @include mq(sp) {
    padding-left: 36px;
    font-size: 16px;
  }
  &::before {
    position: absolute;
    top: 19px;
    left: 20px;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid $red;
    border-right: 2px solid $red;
    transform: rotate(45deg);
    @include mq(sp) {
      top: 17px;
      left: 16px;
    }
  }
  a {
    color: $red;
  }
}

[data-bgb] h4:not([class]):first-child {
	margin-top: 0;
}

[data-bgb] h5:not([class]) {
	margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
  @include mq(sp) {
  }
}
[data-bgb] h5:not([class]):first-child {
	margin-top: 0;
}
[data-bgb] h6:not([class]) {
	position: relative;
  margin-bottom: 20px;
  padding-left: 1.3em;
  font-weight: 700;
  @include mq(sp) {
  }
  &:not([class])::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '■';
    display: block;
    color: $base;
  }
}
[data-bgb] h6:not([class]):first-child {
	margin-top: 0;
}
[data-bgb] p {
	margin: 1em 0;
}
[data-bgb] p:first-child {
	margin-top: 0;
}
[data-bgb] p:last-child {
  margin-bottom: 0;
}
[data-bgb] li {
  margin-bottom: 10px;
  line-height: 1.5;
}
[data-bgb] li:first-child {
	margin-top: 0;
}
[data-bgb] ul {
	margin: 1em 0;
}
[data-bgb] ul:first-child {
	margin-top: 0;
}
[data-bgb] ul:not([class]) li {
  position: relative;
  padding-left: 20px;
  font-size: 16px;
  list-style: none;
  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: $base;
  }
  strong {
    font-weight: 700;
  }
}
[data-bgb] ul.list-01 li {
  position: relative;
  padding-left: 20px;
  font-size: 16px;
  list-style: none;
  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $blue;
  }
}
[data-bgb] ul.list-01.orange li::before {
  background: $orange;
}
[data-bgb] ul.list-01.green li::before {
  background: $green;
}
[data-bgb] ul.list-01.purple li::before {
  background: $purple;
}
[data-bgb] ul.list-01.red li::before {
  background: $red;
}
[data-bgb] ul.list-02 li {
  position: relative;
  padding-left: 20px;
  font-size: 16px;
  list-style: none;
  &::before {
    position: absolute;
    top: 8px;
    left: 0;
    content: '';
    display: block;
    width: 10px;
    height: 12px;
    background: $blue;
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
  }
}
[data-bgb] ul.list-02.orange li::before {
  background: $orange;
}
[data-bgb] ul.list-02.green li::before {
  background: $green;
}
[data-bgb] ul.list-02.purple li::before {
  background: $purple;
}
[data-bgb] ul.list-02.red li::before {
  background: $red;
}

[data-bgb] ul.list-03 li {
  position: relative;
  padding-left: 20px;
  background: url(../img/common/icn_list.svg) left 6px / 17px 15px no-repeat;
  font-size: 16px;
  list-style: none;
}

[data-bgb] ol {
  margin: 1em 0
}

[data-bgb] ol:first-child {
  margin-top: 0
}

[data-bgb] ol ol {
  margin: 0 0 0 2em
}

[data-bgb] ol ol:first-child {
  margin-top: 0
}

[data-bgb] ol:not([class]) li {
  overflow: visible;
  list-style-position: inside;
  list-style-type: decimal;
  margin-left: 20px;
  text-indent: -20px;
}
[data-bgb] ol.list-01 li,
[data-bgb] ol.list-02 li {
  position: relative;
  padding-left: 30px;
  counter-increment: li;
  list-style: none;
}
[data-bgb] ol.list-01 li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: counter(li) "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background: $gra-blue;
  color: white;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
[data-bgb] ol.list-01.orange li::before {
  background: $gra-orange;
}
[data-bgb] ol.list-01.green li::before {
  background: $gra-green;
}
[data-bgb] ol.list-01.purple li::before {
  background: $gra-purple;
}
[data-bgb] ol.list-01.red li::before {
  background: $gra-red;
}

[data-bgb] ol.list-02 li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: counter(li) "";
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $light-blue;
  color: $base;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}
[data-bgb] ol.list-02.orange li::before {
  background: $light-orange;
}
[data-bgb] ol.list-02.green li::before {
  background: $light-green;
}
[data-bgb] ol.list-02.purple li::before {
  background: $light-purple;
}
[data-bgb] ol.list-02.red li::before {
  background: $light-red;
}

[data-bgb] ol li > * {
  text-indent: 0;
}

[data-bgb] blockquote {
  position: relative;
  margin: 1em 0;
  padding: 1px 2.5em;
  border-radius: 5px;
  background: none;
  border: 2px solid #ccc;
  overflow: hidden;
  color: #888;
  font-size: .9em
}

[data-bgb] blockquote:first-child {
  margin-top: 0
}

[data-bgb] blockquote:before {
  content: "“";
  top: .3em;
  left: .25em
}

[data-bgb] blockquote:after, [data-bgb] blockquote:before {
  display: block;
  font-family: serif;
  position: absolute;
  z-index: 0;
  color: #ccc;
  font-size: 3em
}

[data-bgb] blockquote:after {
  content: "”";
  bottom: -.3em;
  right: .25em
}

[data-bgb] table {
  tr {
    th {
      padding: 18px;
      border: 1px solid $gray;
      background: $light-gray;
      line-height: 1.5;
      @include mq(sp) {
        padding: 10px;
      }
    }
    td {
      padding: 18px;
      border: 1px solid $gray;
      line-height: 1.5;
      @include mq(sp) {
        padding: 10px;
      }
    }
  }
}
[data-bgb].orange table tr th {
  background: $light-orange;
}
[data-bgb].green table tr th {
  background: $light-green;
}
[data-bgb].purple table tr th {
  background: $light-purple;
}
[data-bgb].red table tr th {
  background: $light-red;
}

[data-bgb] table:first-child {
  margin-top: 0
}

[data-bgb] table caption {
  font-weight: 700;
  text-align: left
}

@media screen and (max-width:768px) {
  [data-bgb] table caption, [data-bgb] table tbody, [data-bgb] table tfoot {
    display: block
  }
}

@media screen and (max-width:768px) {
  [data-bgb] table:not([class]) tr {
    display: block;
    margin-top: -1px
  }
}

[data-bgb] table tr td, [data-bgb] table tr th {
  text-align: left;
  padding: .5em;
  border: 1px solid #ccc
}

@media screen and (max-width:768px) {
  [data-bgb] table:not([class]) tr td, [data-bgb] table:not([class]) tr th {
    width: auto;
    display: block
  }
}

[data-bgb] table tr th {
  padding: 20px;
  font-weight: 700;
  background: $light-blue;
}

@media screen and (max-width:768px) {
  [data-bgb] table:not([class]) tr th {
    border-bottom: none
  }
}

[data-bgb] table tr td {
  padding: 20px;
  font-weight: 400;
}

[data-bgb].table-01 table {
  border-collapse: separate;
  border-spacing: 10px;
  @include mq(sp) {
    border-spacing: 5px;
  }
}
[data-bgb].table-01 table tr th {
  border: 0;
  background: $blue;
  color: white;
}
[data-bgb].table-01 table tr td {
  border: 0;
  background: $light-gray;
}

[data-bgb].table-01.orange table tr th {
  background: $orange;
}
[data-bgb].table-01.green table tr th {
  background: $green;
}
[data-bgb].table-01.purple table tr th {
  background: $purple;
}
[data-bgb].table-01.red table tr th {
  background: $red;
}


[data-bgb] strong {
  font-weight: 700;
  font-style: normal;
  text-decoration: none
}

[data-bgb] u {
  text-decoration: underline
}

[data-bgb] s, [data-bgb] u {
  font-weight: 400;
  font-style: normal
}

[data-bgb] s {
  text-decoration: line-through
}

[data-bgb] sub {
  vertical-align: sub
}

[data-bgb] sub, [data-bgb] sup {
  font-size: .8em;
  font-weight: 400;
  font-style: normal;
  text-decoration: none
}

[data-bgb] sup {
  vertical-align: super
}

[data-bgb] hr {
  width: 100%;
  height: 2px;
  color: $blue;
  background: currentColor;
  border-style: none;
  margin: 50px auto
}
[data-bgb].blue hr {
  color: $blue;
}
[data-bgb].orange hr {
  color: $orange;
}
[data-bgb].green hr {
  color: $green;
}
[data-bgb].purple hr {
  color: $purple;
}
[data-bgb].red hr {
  color: $red;
}

[data-bgb] hr:first-child {
  margin-top: 0
}

.bgt-box__caption {
  text-align: left;
  font-weight: 400;
  padding: 3px;
  background: none;
  font-size: .8em
}

[data-bgb="button"] [data-bgt="button"] {
  max-width: 100%;
}

.bgt-btn,
.button-bl a, .button-or a, .button-gr a, .button-pp a, .button-rd a {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  border-width: 1px;
  border-radius: 40px;
  border-style: solid;
  background: #fff;
  text-decoration: none;
  line-height: 1.4;
  @include mq(sp) {
  }
  .bgt-btn__text, span {
    position: relative;
    z-index: 1;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    display: inline-block;

  }
}

.bgt-btn:active, .bgt-btn:focus {
  -webkit-box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
  box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff
}

.bgt-btn--link, [data-bgt-button-kind=link] .bgt-btn, [data-bgt-button-kind=link-or] .bgt-btn, [data-bgt-button-kind=link-pp] .bgt-btn, [data-bgt-button-kind=link-gr] .bgt-btn, [data-bgt-button-kind=link-rd] .bgt-btn,
.button-bl a, .button-or a, .button-gr a, .button-pp a, .button-rd a {
  position: relative;
  border-color: $main;
  color: $main;
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    background: linear-gradient(90deg, rgba(67, 193, 223, 1) 0%, rgba(72, 184, 224, 1) 24.49%, rgba(79, 171, 225, 1) 46.32%, rgba(87, 147, 227, 1) 99.44%);
    opacity: 0;
    transition: .5s;
  }
  .bgt-btn__text, span {
    background: url(../img/common/icn_btn_bl.svg) 10px center / 38px 38px no-repeat;
    min-width: 280px;
    max-width: 100%;
    padding: 18px 20px 18px 55px;
  }
}

[data-bgt-button-kind=link-or] .bgt-btn, .button-or a {
  border-color: $orange;
  color: $orange;
  &::after {
    background: $gra-orange;
  }
  .bgt-btn__text, span {
    background: url(../img/common/icn_btn_or.svg) 10px center / 38px 38px no-repeat;
  }
}
[data-bgt-button-kind=link-pp] .bgt-btn, .button-pp a {
  border-color: $purple;
  color: $purple;
  &::after {
    background: $gra-purple;
  }
  .bgt-btn__text, span {
    background: url(../img/common/icn_btn_pp.svg) 10px center / 38px 38px no-repeat;
  }
}
[data-bgt-button-kind=link-gr] .bgt-btn, .button-gr a {
  border-color: $green;
  color: $green;
  &::after {
    background: $gra-green;
  }
  .bgt-btn__text, span {
    background: url(../img/common/icn_btn_gr.svg) 10px center / 38px 38px no-repeat;
  }
}
[data-bgt-button-kind=link-rd] .bgt-btn, .button-rd a {
  border-color: $red;
  color: $red;
  &::after {
    background: $gra-red;
  }
  .bgt-btn__text, span {
    background: url(../img/common/icn_btn_rd.svg) 10px center / 38px 38px no-repeat;
  }
}


.bgt-btn--link:active, .bgt-btn--link:focus, .bgt-btn--link:hover, [data-bgt-button-kind=link] .bgt-btn:active, [data-bgt-button-kind=link] .bgt-btn:focus, [data-bgt-button-kind=link] .bgt-btn:hover, [data-bgt-button-kind=link-or] .bgt-btn:hover, [data-bgt-button-kind=link-gr] .bgt-btn:hover, [data-bgt-button-kind=link-pp] .bgt-btn:hover, [data-bgt-button-kind=link-rd] .bgt-btn:hover,
.button-bl a:hover, .button-or a:hover, .button-gr a:hover, .button-pp a:hover, .button-rd a:hover {
  @include mq(pc) {
    border-color: white;
    color: white;
  }
  &::after {
    @include mq(pc) {
      opacity: 1;
    }
  }
  .bgt-btn__text, span {
    background: url(../img/common/icn_btn_bl_h.svg) 10px center / 38px 38px no-repeat;
  }
}

[data-bgt-button-kind=link-or] .bgt-btn:hover, .button-or a:hover {
  .bgt-btn__text, span {
    @include mq(pc) {
      background: url(../img/common/icn_btn_or_h.svg) 10px center / 38px 38px no-repeat;
    }
  }
}
[data-bgt-button-kind=link-gr] .bgt-btn:hover, .button-gr a:hover {
  .bgt-btn__text, span {
    @include mq(pc) {
      background: url(../img/common/icn_btn_gr_h.svg) 10px center / 38px 38px no-repeat;
    }
  }
}
[data-bgt-button-kind=link-pp] .bgt-btn:hover, .button-pp a:hover {
  .bgt-btn__text, span {
    @include mq(pc) {
      background: url(../img/common/icn_btn_pp_h.svg) 10px center / 38px 38px no-repeat;
    }
  }
}
[data-bgt-button-kind=link-rd] .bgt-btn:hover, .button-rd a:hover {
  .bgt-btn__text,span {
    @include mq(pc) {
      background: url(../img/common/icn_btn_rd_h.svg) 10px center / 38px 38px no-repeat;
    }
  }
}

.-anchor_link [data-bgt-button-kind=link] .bgt-btn {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  padding: 18px 20px;
  background: none;
  background-color: white;
  border: 1px solid $main;
  border-radius: 100px;
  color: $main;
  font-weight: 400;
  line-height: 1.3;
  text-align: center;
  @include mq(sp) {
    padding: 13px 20px;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    width: 16px;
    height: 16px;
    background-size: 100% auto;
    transform: translateY(-50%) rotate(90deg);
  }
  &:hover {
    @include mq(pc) {
      background-color: $main;
    }
    &::after {
      transform: translateY(-50%) rotate(90deg);
    }
    @include mq(pc) {
      color: white;
    }
  }
}

.bgt-btn--em, [data-bgt-button-kind=em] .bgt-btn {
  position: relative;
  min-width: 280px;
  max-width: 100%;
  padding: 18px 40px;
  border: 0;
  border-radius: 0;
  background: #333;
  color: #fff;
  font-weight: 700;
  clip-path: polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px);

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: $gra-blue;
    clip-path: polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px);
    opacity: 0;
    transition: .5s;
  }
  .bgt-btn__text {
    position: relative;
    z-index: 1;
  }
}

.bgt-btn--em:active, .bgt-btn--em:focus, .bgt-btn--em:hover, [data-bgt-button-kind=em] .bgt-btn:active, [data-bgt-button-kind=em] .bgt-btn:focus, [data-bgt-button-kind=em] .bgt-btn:hover {
  &::after {
    @include mq(pc) {
      opacity: 1;
    }
  }
}

.bgt-btn--external, [data-bgt-button-kind=external] .bgt-btn {
  color: #333;
  border-color: #ccc;
  background: #fff;
  min-width: 280px;
  max-width: 100%;
  padding: 18px 40px;
  border-radius: 0;
}

.bgt-btn--external:active, .bgt-btn--external:focus, .bgt-btn--external:hover, [data-bgt-button-kind=external] .bgt-btn:active, [data-bgt-button-kind=external] .bgt-btn:focus, [data-bgt-button-kind=external] .bgt-btn:hover {
  @include mq(pc) {
    background-color: $sub;
  }
}

.bgt-btn--external:after, [data-bgt-button-kind=external] .bgt-btn:after {
  font-family: FontAwesome, serif;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f08e";
  font-size: 1em;
  color: #999;
  vertical-align: middle;
}

.bgt-btn--back, [data-bgt-button-kind=back] .bgt-btn {
  color: #333;
  border-color: #ddd;
  background: #ddd;
}

.bgt-btn--back:active, .bgt-btn--back:focus, .bgt-btn--back:hover, [data-bgt-button-kind=back] .bgt-btn:active, [data-bgt-button-kind=back] .bgt-btn:focus, [data-bgt-button-kind=back] .bgt-btn:hover {
  @include mq(pc) {
    background: #e9e9e9;
  }
}

.bgt-btn--back span, [data-bgt-button-kind=back] .bgt-btn span {
  display: inline-block;
  vertical-align: middle
}

.bgt-btn--back:before, [data-bgt-button-kind=back] .bgt-btn:before {
  font-family: FontAwesome, serif;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f053";
  font-size: 1em;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle
}

.bgt-hr {
  margin: 0 auto
}

[data-bgt-hr-kind=dashed] .bgt-hr {
  height: 0;
  background: none;
  border: none;
  border-bottom: 2px dashed
}

.bgt-hr--bold, [data-bgt-hr-kind=bold] .bgt-hr {
  height: 5px
}

.bgt-hr--narrow, [data-bgt-hr-kind=narrow] .bgt-hr {
  height: 1px
}

.bgt-hr--short, [data-bgt-hr-kind=short] .bgt-hr {
  width: 60%;
  height: 1px
}

.bgt-download-file__link {
  text-align: left;
  font-size: 1.08em;
  font-weight: 700;
  display: inline-block;
  padding: .7em 1em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid $main;
  background-color: #fff;
  color: $main;
  line-height: 1;
  text-decoration: none;
  &:hover {
    @include mq(pc) {
      color: $main;
    }
  }
  .bgt-link__name {
    vertical-align: middle;
  }
  .bgt-link__icon {
    &::after {
      content: '\f019' !important;
      vertical-align: middle;
    }
  }
  .bgt-link__icon.bgt-link__icon--before {
    display: none;
  }
  .bgt-link__icon.bgt-link__icon--after {
    display: inline-block;
  }
}

.bgt-download-file__link:hover {
  @include mq(pc) {
    background-color: $light-yellow;
  }
}

.bgt-download-file__link:active, .bgt-download-file__link:focus {
  -webkit-box-shadow: 0 0 5px 1px #0570c7;
  box-shadow: 0 0 5px 1px #0570c7;
  outline: none
}

.bgt-link__size {
  display: none;
  color: grey;
  font-size: .8em;
  font-weight: 400
}

@media screen and (max-width:768px) {
  .bgt-google-maps-link {
    margin-top: 10px;
    padding: 1em;
    text-decoration: none;
    color: #333;
    border: 1px solid #999;
    border-radius: 3px;
    text-align: center
  }
  .bgt-google-maps-link span {
    display: inline-block;
    vertical-align: middle
  }
  .bgt-google-maps-link:after {
    font-family: FontAwesome, serif;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f08e";
    font-size: 1em;
    vertical-align: middle
  }
}

[data-bgt=gallery] .bgt-gallery {
  padding-top: 54%
}

[data-bgt=gallery] .bgt-gallery:hover [data-bgt=gallery] .bgt-gallery-ctrl {
  opacity: 1
}

[data-bgt=gallery] .bgt-gallery__img img {
  -o-object-fit: cover;
  font-family: "object-fit:cover";
  object-fit: cover
}

[data-bgt=gallery] .bgt-gallery__caption {
  color: #fff;
  background: rgba(0, 0, 0, .6);
  padding: .7em 1em;
  text-align: center
}

[data-bgt=gallery] .bgt-gallery-ctrl {
  top: 50%;
  opacity: 0;
  -webkit-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in
}

[data-bgt=gallery] .bgt-gallery-ctrl__next, [data-bgt=gallery] .bgt-gallery-ctrl__prev {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: -20px 0 0;
  padding: 0;
  background: none;
  border: none;
  -webkit-transform-origin: center center;
  transform-origin: center center
}

[data-bgt=gallery] .bgt-gallery-ctrl__next:hover, [data-bgt=gallery] .bgt-gallery-ctrl__prev:hover {
  opacity: .5
}

[data-bgt=gallery] .bgt-gallery-ctrl__next:focus, [data-bgt=gallery] .bgt-gallery-ctrl__prev:focus {
  outline: none
}

[data-bgt=gallery] .bgt-gallery-ctrl__next span, [data-bgt=gallery] .bgt-gallery-ctrl__prev span {
  display: block;
  overflow: hidden;
  width: 0;
  height: 0
}

[data-bgt=gallery] .bgt-gallery-ctrl__next:after, [data-bgt=gallery] .bgt-gallery-ctrl__prev:after {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  border: solid #fff;
  border-width: 5px 5px 0 0
}

[data-bgt=gallery] .bgt-gallery-ctrl__prev {
  left: 20px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg)
}

[data-bgt=gallery] .bgt-gallery-ctrl__next {
  right: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

[data-bgt=gallery] [data-gallery-marker=thumbs]+.bgt-gallery-marker {
  margin-top: 10px
}

[data-bgt=gallery] [data-gallery-marker=thumbs]+.bgt-gallery-marker li {
  margin: 0 5px;
  width: 60px;
  height: 60px
}

[data-bgt=gallery] [data-gallery-marker=dot]+.bgt-gallery-marker {
  margin-top: 10px
}

[data-bgt=gallery] [data-gallery-marker=dot]+.bgt-gallery-marker li {
  margin: 0 2px;
  width: 10px;
  height: 10px;
  background-color: #999;
  border-radius: 100%
}

[data-bgt=gallery] [data-gallery-marker=dot]+.bgt-gallery-marker li.current {
  background-color: #000
}

[data-bgb=image1] .bgt-box__caption {
  text-align: center;
}


.pc-only {
  display: block
}

@media screen and (max-width:768px) {
  .pc-only {
    display: none
  }
}

.sp-only {
  display: none
}

@media screen and (max-width:768px) {
  .sp-only {
    display: block
  }
}
