$base: #2c2c2c;
$black: #333333;
$black-2: #231815;
$gray: #cccccc;
$soft-gray: #e5e5e5;
$light-gray: #f3f3f3;
$dark-gray: #8D8D8E;
$main: #0078c1;
$sub: #EDF6FE;

$blue: #009FE8;
$light-blue: #E4F4FD;
$orange: #F0832C;
$light-orange: #FDE7D3;
$green: #82B140;
$light-green: #EAF3DE;
$red: #E60441;
$light-red: #F8E2E3;
$purple: #A05A94;
$light-purple: #EEE0EB;

$news-green: #30a589;

$yellow: #fff9a7;
$light-yellow: #FFFCD7;
$title-gray: #EBEBEC;

$gra-blue: linear-gradient(90deg, rgba(67, 193, 223, 1) 0%, rgba(72, 184, 224, 1) 24.49%, rgba(79, 171, 225, 1) 46.32%, rgba(87, 147, 227, 1) 99.44%);
$gra-orange: linear-gradient(90deg, rgba(243, 169, 82, 1) 0%, rgba(242, 160, 80, 1) 16.96%, rgba(241, 134, 73, 1) 45.09%, rgba(241, 133, 73, 1) 46.32%, rgba(240, 96, 62, 1) 99.44%);
$gra-green: linear-gradient(90deg, rgba(190, 216, 75, 1) 0%, rgba(181, 216, 79, 1) 35.79%, rgba(177, 216, 81, 1) 45.67%, rgba(154, 215, 92, 1) 99.44%);
$gra-red: linear-gradient(90deg, rgba(233, 147, 144, 1) 0.12%, rgba(231, 138, 139, 1) 17.04%, rgba(227, 112, 125, 1) 45.09%, rgba(227, 111, 124, 1) 46.32%, rgba(224, 85, 101, 1) 100%);
$gra-purple: linear-gradient(90deg, rgba(202, 158, 199, 1) 0.12%, rgba(199, 149, 196, 1) 16.7%, rgba(193, 123, 188, 1) 44.18%, rgba(192, 121, 187, 1) 46.32%, rgba(160, 100, 168, 1) 99.44%);

$gradation-blue: linear-gradient(to right,  #43c1df 0%,#4fabe1 46%,#5793e3 100%);
$gradation-orange: linear-gradient(to right,  #F3A952 0%,#F18549 46%,#F0603E 100%);
$gradation-green: linear-gradient(to right, #BED84B 0%,#B1D851 46%,#9AD75C 100%);
$gradation-red: linear-gradient(to right, #e99390 0%,#e36d7a 46%,#e05565 100%);
$gradation-purple: linear-gradient(to right, #ca9ec7 0%,#be77ba 46%,#a064a8 100%);

$base-lh: 2;
$base-fs: 16px;
$sp-fs: 16px;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Noto+Serif+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@1,600&display=swap');

$font-notosans: 'Noto Sans JP', sans-serif;
$font-notoserif: 'Noto Serif JP', serif;
$font-shipporiserif: 'Shippori Mincho', serif;
$font-firasans: 'Fira Sans', serif;
