@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Noto+Serif+JP:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@1,600&display=swap");
/*!
 *
 * BurgerEditor StyleSheet Style Sample v2.21.0
 *
 */
[data-bgb] h2:not([class]),
[data-bgb] .bgt-title-h2-container {
  position: relative;
  margin-bottom: 30px;
  padding: 10px 20px;
  background: #e5e5e5;
  color: white;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.4;
}

@media screen and (max-width: 760px) {
  [data-bgb] h2:not([class]),
  [data-bgb] .bgt-title-h2-container {
    padding: 8px 12px;
    font-size: 17px;
  }
}

[data-bgb] h2:not([class]):not([class])::before, [data-bgb] h2:not([class]).bgt-title-h2-container::before,
[data-bgb] .bgt-title-h2-container:not([class])::before,
[data-bgb] .bgt-title-h2-container.bgt-title-h2-container::before {
  position: absolute;
  top: 5px;
  right: 5px;
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  background: #009FE8;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
}

@media screen and (max-width: 760px) {
  [data-bgb] h2:not([class]):not([class])::before, [data-bgb] h2:not([class]).bgt-title-h2-container::before,
  [data-bgb] .bgt-title-h2-container:not([class])::before,
  [data-bgb] .bgt-title-h2-container.bgt-title-h2-container::before {
    width: 8px;
    height: 8px;
  }
}

[data-bgb] h2:not([class]):not([class])::after, [data-bgb] h2:not([class]).bgt-title-h2-container::after,
[data-bgb] .bgt-title-h2-container:not([class])::after,
[data-bgb] .bgt-title-h2-container.bgt-title-h2-container::after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: block;
  width: calc(100% - 150px);
  height: 100%;
  background: #009FE8;
  clip-path: polygon(0 0, 100% 0%, calc(100% - 50px) 100%, 0% 100%);
}

@media screen and (max-width: 760px) {
  [data-bgb] h2:not([class]):not([class])::after, [data-bgb] h2:not([class]).bgt-title-h2-container::after,
  [data-bgb] .bgt-title-h2-container:not([class])::after,
  [data-bgb] .bgt-title-h2-container.bgt-title-h2-container::after {
    width: calc(100% - 40px);
    clip-path: polygon(0 0, 100% 0%, calc(100% - 30px) 100%, 0% 100%);
  }
}

[data-bgb] h2:not([class]):not([class]) span,
[data-bgb] h2:not([class]) h2.bge-title-h2,
[data-bgb] .bgt-title-h2-container:not([class]) span,
[data-bgb] .bgt-title-h2-container h2.bge-title-h2 {
  position: relative;
  z-index: 1;
  padding-right: 160px;
}

@media screen and (max-width: 760px) {
  [data-bgb] h2:not([class]):not([class]) span,
  [data-bgb] h2:not([class]) h2.bge-title-h2,
  [data-bgb] .bgt-title-h2-container:not([class]) span,
  [data-bgb] .bgt-title-h2-container h2.bge-title-h2 {
    padding-right: 60px;
  }
}

[data-bgb] h2.blue::before,
[data-bgb].blue .bgt-title-h2-container::before,
[data-bgb] h2.blue::after,
[data-bgb].blue .bgt-title-h2-container::after {
  background: #009FE8;
}

[data-bgb] h2.orange::before,
[data-bgb].orange .bgt-title-h2-container::before,
[data-bgb] h2.orange::after,
[data-bgb].orange .bgt-title-h2-container::after {
  background: #F0832C;
}

[data-bgb] h2.green::before,
[data-bgb].green .bgt-title-h2-container::before,
[data-bgb] h2.green::after,
[data-bgb].green .bgt-title-h2-container::after {
  background: #82B140;
}

[data-bgb] h2.purple::before,
[data-bgb].purple .bgt-title-h2-container::before,
[data-bgb] h2.purple::after,
[data-bgb].purple .bgt-title-h2-container::after {
  background: #A05A94;
}

[data-bgb] h2.red::before,
[data-bgb].red .bgt-title-h2-container::before,
[data-bgb] h2.red::after,
[data-bgb].red .bgt-title-h2-container::after {
  background: #E60441;
}

[data-bgb] h2:not([class]):first-child,
[data-bgb] h2.bge-title-h2:first-child {
  margin-top: 0;
}

[data-bgb] h3:not([class]),
[data-bgb] .bge-title-h3 {
  margin-bottom: 30px;
  padding-left: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 3px solid #009FE8;
  background: url(../img/common/icn_title.svg) left top no-repeat;
  background-size: 27px 47px;
  font-size: 23px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  [data-bgb] h3:not([class]),
  [data-bgb] .bge-title-h3 {
    padding: 5px 0 5px 25px;
    border-width: 2px;
    background-position: left 5px;
    background-size: 17px 29px;
    font-size: 18px;
    line-height: 1.5;
  }
}

[data-bgb] h3.blue,
[data-bgb].blue .bge-title-h3 {
  margin-bottom: 30px;
  padding-left: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 3px solid #009FE8;
  background: url(../img/common/icn_title.svg) left top no-repeat;
  background-size: 27px 47px;
  font-size: 23px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  [data-bgb] h3.blue,
  [data-bgb].blue .bge-title-h3 {
    padding: 5px 0 5px 25px;
    border-width: 2px;
    background-position: left 5px;
    background-size: 17px 29px;
    font-size: 18px;
    line-height: 1.5;
  }
}

[data-bgb] h3.orange,
[data-bgb].orange .bge-title-h3 {
  margin-bottom: 30px;
  padding-left: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 3px solid #F0832C;
  background: url(../img/common/icn_title_or.svg) left top no-repeat;
  background-size: 27px 47px;
  font-size: 23px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  [data-bgb] h3.orange,
  [data-bgb].orange .bge-title-h3 {
    padding: 5px 0 5px 25px;
    border-width: 2px;
    background-position: left 5px;
    background-size: 17px 29px;
    font-size: 18px;
    line-height: 1.5;
  }
}

[data-bgb] h3.green,
[data-bgb].green .bge-title-h3 {
  margin-bottom: 30px;
  padding-left: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 3px solid #82B140;
  background: url(../img/common/icn_title_gr.svg) left top no-repeat;
  background-size: 27px 47px;
  font-size: 23px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  [data-bgb] h3.green,
  [data-bgb].green .bge-title-h3 {
    padding: 5px 0 5px 25px;
    border-width: 2px;
    background-position: left 5px;
    background-size: 17px 29px;
    font-size: 18px;
    line-height: 1.5;
  }
}

[data-bgb] h3.purple,
[data-bgb].purple .bge-title-h3 {
  margin-bottom: 30px;
  padding-left: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 3px solid #A05A94;
  background: url(../img/common/icn_title_pp.svg) left top no-repeat;
  background-size: 27px 47px;
  font-size: 23px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  [data-bgb] h3.purple,
  [data-bgb].purple .bge-title-h3 {
    padding: 5px 0 5px 25px;
    border-width: 2px;
    background-position: left 5px;
    background-size: 17px 29px;
    font-size: 18px;
    line-height: 1.5;
  }
}

[data-bgb] h3.red,
[data-bgb].red .bge-title-h3 {
  margin-bottom: 30px;
  padding-left: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 3px solid #E60441;
  background: url(../img/common/icn_title_rd.svg) left top no-repeat;
  background-size: 27px 47px;
  font-size: 23px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  [data-bgb] h3.red,
  [data-bgb].red .bge-title-h3 {
    padding: 5px 0 5px 25px;
    border-width: 2px;
    background-position: left 5px;
    background-size: 17px 29px;
    font-size: 18px;
    line-height: 1.5;
  }
}

[data-bgb] h3:not([class]):first-child,
[data-bgb] h3.bge-title-h3:first-child {
  margin-top: 0;
}

[data-bgb] h4:not([class]) {
  position: relative;
  margin-bottom: 30px;
  background: #E4F4FD;
  padding: 10px 18px 10px 40px;
  color: #009FE8;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.4;
}

@media screen and (max-width: 760px) {
  [data-bgb] h4:not([class]) {
    padding-left: 36px;
    font-size: 16px;
  }
}

[data-bgb] h4:not([class])::before {
  position: absolute;
  top: 19px;
  left: 20px;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #009FE8;
  border-right: 2px solid #009FE8;
  transform: rotate(45deg);
}

@media screen and (max-width: 760px) {
  [data-bgb] h4:not([class])::before {
    top: 17px;
    left: 16px;
  }
}

[data-bgb] h4.blue {
  position: relative;
  margin-bottom: 30px;
  background: #E4F4FD;
  padding: 10px 18px 10px 40px;
  color: #009FE8;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.4;
}

@media screen and (max-width: 760px) {
  [data-bgb] h4.blue {
    padding-left: 36px;
    font-size: 16px;
  }
}

[data-bgb] h4.blue::before {
  position: absolute;
  top: 19px;
  left: 20px;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #009FE8;
  border-right: 2px solid #009FE8;
  transform: rotate(45deg);
}

@media screen and (max-width: 760px) {
  [data-bgb] h4.blue::before {
    top: 17px;
    left: 16px;
  }
}

[data-bgb] h4.blue a {
  color: #009FE8;
}

[data-bgb] h4.orange {
  position: relative;
  margin-bottom: 30px;
  background: #FDE7D3;
  padding: 10px 18px 10px 40px;
  color: #F0832C;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.4;
}

@media screen and (max-width: 760px) {
  [data-bgb] h4.orange {
    padding-left: 36px;
    font-size: 16px;
  }
}

[data-bgb] h4.orange::before {
  position: absolute;
  top: 19px;
  left: 20px;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #F0832C;
  border-right: 2px solid #F0832C;
  transform: rotate(45deg);
}

@media screen and (max-width: 760px) {
  [data-bgb] h4.orange::before {
    top: 17px;
    left: 16px;
  }
}

[data-bgb] h4.orange a {
  color: #F0832C;
}

[data-bgb] h4.green {
  position: relative;
  margin-bottom: 30px;
  background: #EAF3DE;
  padding: 10px 18px 10px 40px;
  color: #82B140;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.4;
}

@media screen and (max-width: 760px) {
  [data-bgb] h4.green {
    padding-left: 36px;
    font-size: 16px;
  }
}

[data-bgb] h4.green::before {
  position: absolute;
  top: 19px;
  left: 20px;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #82B140;
  border-right: 2px solid #82B140;
  transform: rotate(45deg);
}

@media screen and (max-width: 760px) {
  [data-bgb] h4.green::before {
    top: 17px;
    left: 16px;
  }
}

[data-bgb] h4.green a {
  color: #82B140;
}

[data-bgb] h4.purple {
  position: relative;
  margin-bottom: 30px;
  background: #EEE0EB;
  padding: 10px 18px 10px 40px;
  color: #A05A94;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.4;
}

@media screen and (max-width: 760px) {
  [data-bgb] h4.purple {
    padding-left: 36px;
    font-size: 16px;
  }
}

[data-bgb] h4.purple::before {
  position: absolute;
  top: 19px;
  left: 20px;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #A05A94;
  border-right: 2px solid #A05A94;
  transform: rotate(45deg);
}

@media screen and (max-width: 760px) {
  [data-bgb] h4.purple::before {
    top: 17px;
    left: 16px;
  }
}

[data-bgb] h4.purple a {
  color: #A05A94;
}

[data-bgb] h4.red {
  position: relative;
  margin-bottom: 30px;
  background: #F8E2E3;
  padding: 10px 18px 10px 40px;
  color: #E60441;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.4;
}

@media screen and (max-width: 760px) {
  [data-bgb] h4.red {
    padding-left: 36px;
    font-size: 16px;
  }
}

[data-bgb] h4.red::before {
  position: absolute;
  top: 19px;
  left: 20px;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #E60441;
  border-right: 2px solid #E60441;
  transform: rotate(45deg);
}

@media screen and (max-width: 760px) {
  [data-bgb] h4.red::before {
    top: 17px;
    left: 16px;
  }
}

[data-bgb] h4.red a {
  color: #E60441;
}

[data-bgb] h4:not([class]):first-child {
  margin-top: 0;
}

[data-bgb] h5:not([class]) {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
}

[data-bgb] h5:not([class]):first-child {
  margin-top: 0;
}

[data-bgb] h6:not([class]) {
  position: relative;
  margin-bottom: 20px;
  padding-left: 1.3em;
  font-weight: 700;
}

[data-bgb] h6:not([class]):not([class])::before {
  position: absolute;
  left: 0;
  top: 0;
  content: '■';
  display: block;
  color: #2c2c2c;
}

[data-bgb] h6:not([class]):first-child {
  margin-top: 0;
}

[data-bgb] p {
  margin: 1em 0;
}

[data-bgb] p:first-child {
  margin-top: 0;
}

[data-bgb] p:last-child {
  margin-bottom: 0;
}

[data-bgb] li {
  margin-bottom: 10px;
  line-height: 1.5;
}

[data-bgb] li:first-child {
  margin-top: 0;
}

[data-bgb] ul {
  margin: 1em 0;
}

[data-bgb] ul:first-child {
  margin-top: 0;
}

[data-bgb] ul:not([class]) li {
  position: relative;
  padding-left: 20px;
  font-size: 16px;
  list-style: none;
}

[data-bgb] ul:not([class]) li::before {
  position: absolute;
  top: 9px;
  left: 0;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background: #2c2c2c;
}

[data-bgb] ul:not([class]) li strong {
  font-weight: 700;
}

[data-bgb] ul.list-01 li {
  position: relative;
  padding-left: 20px;
  font-size: 16px;
  list-style: none;
}

[data-bgb] ul.list-01 li::before {
  position: absolute;
  top: 9px;
  left: 0;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #009FE8;
}

[data-bgb] ul.list-01.orange li::before {
  background: #F0832C;
}

[data-bgb] ul.list-01.green li::before {
  background: #82B140;
}

[data-bgb] ul.list-01.purple li::before {
  background: #A05A94;
}

[data-bgb] ul.list-01.red li::before {
  background: #E60441;
}

[data-bgb] ul.list-02 li {
  position: relative;
  padding-left: 20px;
  font-size: 16px;
  list-style: none;
}

[data-bgb] ul.list-02 li::before {
  position: absolute;
  top: 8px;
  left: 0;
  content: '';
  display: block;
  width: 10px;
  height: 12px;
  background: #009FE8;
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
}

[data-bgb] ul.list-02.orange li::before {
  background: #F0832C;
}

[data-bgb] ul.list-02.green li::before {
  background: #82B140;
}

[data-bgb] ul.list-02.purple li::before {
  background: #A05A94;
}

[data-bgb] ul.list-02.red li::before {
  background: #E60441;
}

[data-bgb] ul.list-03 li {
  position: relative;
  padding-left: 20px;
  background: url(../img/common/icn_list.svg) left 6px/17px 15px no-repeat;
  font-size: 16px;
  list-style: none;
}

[data-bgb] ol {
  margin: 1em 0;
}

[data-bgb] ol:first-child {
  margin-top: 0;
}

[data-bgb] ol ol {
  margin: 0 0 0 2em;
}

[data-bgb] ol ol:first-child {
  margin-top: 0;
}

[data-bgb] ol:not([class]) li {
  overflow: visible;
  list-style-position: inside;
  list-style-type: decimal;
  margin-left: 20px;
  text-indent: -20px;
}

[data-bgb] ol.list-01 li,
[data-bgb] ol.list-02 li {
  position: relative;
  padding-left: 30px;
  counter-increment: li;
  list-style: none;
}

[data-bgb] ol.list-01 li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: counter(li) "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background: linear-gradient(90deg, #43c1df 0%, #48b8e0 24.49%, #4fabe1 46.32%, #5793e3 99.44%);
  color: white;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

[data-bgb] ol.list-01.orange li::before {
  background: linear-gradient(90deg, #f3a952 0%, #f2a050 16.96%, #f18649 45.09%, #f18549 46.32%, #f0603e 99.44%);
}

[data-bgb] ol.list-01.green li::before {
  background: linear-gradient(90deg, #bed84b 0%, #b5d84f 35.79%, #b1d851 45.67%, #9ad75c 99.44%);
}

[data-bgb] ol.list-01.purple li::before {
  background: linear-gradient(90deg, #ca9ec7 0.12%, #c795c4 16.7%, #c17bbc 44.18%, #c079bb 46.32%, #a064a8 99.44%);
}

[data-bgb] ol.list-01.red li::before {
  background: linear-gradient(90deg, #e99390 0.12%, #e78a8b 17.04%, #e3707d 45.09%, #e36f7c 46.32%, #e05565 100%);
}

[data-bgb] ol.list-02 li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: counter(li) "";
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #E4F4FD;
  color: #2c2c2c;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

[data-bgb] ol.list-02.orange li::before {
  background: #FDE7D3;
}

[data-bgb] ol.list-02.green li::before {
  background: #EAF3DE;
}

[data-bgb] ol.list-02.purple li::before {
  background: #EEE0EB;
}

[data-bgb] ol.list-02.red li::before {
  background: #F8E2E3;
}

[data-bgb] ol li > * {
  text-indent: 0;
}

[data-bgb] blockquote {
  position: relative;
  margin: 1em 0;
  padding: 1px 2.5em;
  border-radius: 5px;
  background: none;
  border: 2px solid #ccc;
  overflow: hidden;
  color: #888;
  font-size: .9em;
}

[data-bgb] blockquote:first-child {
  margin-top: 0;
}

[data-bgb] blockquote:before {
  content: "“";
  top: .3em;
  left: .25em;
}

[data-bgb] blockquote:after, [data-bgb] blockquote:before {
  display: block;
  font-family: serif;
  position: absolute;
  z-index: 0;
  color: #ccc;
  font-size: 3em;
}

[data-bgb] blockquote:after {
  content: "”";
  bottom: -.3em;
  right: .25em;
}

[data-bgb] table tr th {
  padding: 18px;
  border: 1px solid #cccccc;
  background: #f3f3f3;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  [data-bgb] table tr th {
    padding: 10px;
  }
}

[data-bgb] table tr td {
  padding: 18px;
  border: 1px solid #cccccc;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  [data-bgb] table tr td {
    padding: 10px;
  }
}

[data-bgb].orange table tr th {
  background: #FDE7D3;
}

[data-bgb].green table tr th {
  background: #EAF3DE;
}

[data-bgb].purple table tr th {
  background: #EEE0EB;
}

[data-bgb].red table tr th {
  background: #F8E2E3;
}

[data-bgb] table:first-child {
  margin-top: 0;
}

[data-bgb] table caption {
  font-weight: 700;
  text-align: left;
}

@media screen and (max-width: 768px) {
  [data-bgb] table caption, [data-bgb] table tbody, [data-bgb] table tfoot {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  [data-bgb] table:not([class]) tr {
    display: block;
    margin-top: -1px;
  }
}

[data-bgb] table tr td, [data-bgb] table tr th {
  text-align: left;
  padding: .5em;
  border: 1px solid #ccc;
}

@media screen and (max-width: 768px) {
  [data-bgb] table:not([class]) tr td, [data-bgb] table:not([class]) tr th {
    width: auto;
    display: block;
  }
}

[data-bgb] table tr th {
  padding: 20px;
  font-weight: 700;
  background: #E4F4FD;
}

@media screen and (max-width: 768px) {
  [data-bgb] table:not([class]) tr th {
    border-bottom: none;
  }
}

[data-bgb] table tr td {
  padding: 20px;
  font-weight: 400;
}

[data-bgb].table-01 table {
  border-collapse: separate;
  border-spacing: 10px;
}

@media screen and (max-width: 760px) {
  [data-bgb].table-01 table {
    border-spacing: 5px;
  }
}

[data-bgb].table-01 table tr th {
  border: 0;
  background: #009FE8;
  color: white;
}

[data-bgb].table-01 table tr td {
  border: 0;
  background: #f3f3f3;
}

[data-bgb].table-01.orange table tr th {
  background: #F0832C;
}

[data-bgb].table-01.green table tr th {
  background: #82B140;
}

[data-bgb].table-01.purple table tr th {
  background: #A05A94;
}

[data-bgb].table-01.red table tr th {
  background: #E60441;
}

[data-bgb] strong {
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
}

[data-bgb] u {
  text-decoration: underline;
}

[data-bgb] s, [data-bgb] u {
  font-weight: 400;
  font-style: normal;
}

[data-bgb] s {
  text-decoration: line-through;
}

[data-bgb] sub {
  vertical-align: sub;
}

[data-bgb] sub, [data-bgb] sup {
  font-size: .8em;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
}

[data-bgb] sup {
  vertical-align: super;
}

[data-bgb] hr {
  width: 100%;
  height: 2px;
  color: #009FE8;
  background: currentColor;
  border-style: none;
  margin: 50px auto;
}

[data-bgb].blue hr {
  color: #009FE8;
}

[data-bgb].orange hr {
  color: #F0832C;
}

[data-bgb].green hr {
  color: #82B140;
}

[data-bgb].purple hr {
  color: #A05A94;
}

[data-bgb].red hr {
  color: #E60441;
}

[data-bgb] hr:first-child {
  margin-top: 0;
}

.bgt-box__caption {
  text-align: left;
  font-weight: 400;
  padding: 3px;
  background: none;
  font-size: .8em;
}

[data-bgb="button"] [data-bgt="button"] {
  max-width: 100%;
}

.bgt-btn,
.button-bl a, .button-or a, .button-gr a, .button-pp a, .button-rd a {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  border-width: 1px;
  border-radius: 40px;
  border-style: solid;
  background: #fff;
  text-decoration: none;
  line-height: 1.4;
}

.bgt-btn .bgt-btn__text, .bgt-btn span,
.button-bl a .bgt-btn__text,
.button-bl a span, .button-or a .bgt-btn__text, .button-or a span, .button-gr a .bgt-btn__text, .button-gr a span, .button-pp a .bgt-btn__text, .button-pp a span, .button-rd a .bgt-btn__text, .button-rd a span {
  position: relative;
  z-index: 1;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  display: inline-block;
}

.bgt-btn:active, .bgt-btn:focus {
  -webkit-box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
  box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
}

.bgt-btn--link, [data-bgt-button-kind=link] .bgt-btn, [data-bgt-button-kind=link-or] .bgt-btn, [data-bgt-button-kind=link-pp] .bgt-btn, [data-bgt-button-kind=link-gr] .bgt-btn, [data-bgt-button-kind=link-rd] .bgt-btn,
.button-bl a, .button-or a, .button-gr a, .button-pp a, .button-rd a {
  position: relative;
  border-color: #0078c1;
  color: #0078c1;
}

.bgt-btn--link::after, [data-bgt-button-kind=link] .bgt-btn::after, [data-bgt-button-kind=link-or] .bgt-btn::after, [data-bgt-button-kind=link-pp] .bgt-btn::after, [data-bgt-button-kind=link-gr] .bgt-btn::after, [data-bgt-button-kind=link-rd] .bgt-btn::after,
.button-bl a::after, .button-or a::after, .button-gr a::after, .button-pp a::after, .button-rd a::after {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background: linear-gradient(90deg, #43c1df 0%, #48b8e0 24.49%, #4fabe1 46.32%, #5793e3 99.44%);
  opacity: 0;
  transition: .5s;
}

.bgt-btn--link .bgt-btn__text, .bgt-btn--link span, [data-bgt-button-kind=link] .bgt-btn .bgt-btn__text, [data-bgt-button-kind=link] .bgt-btn span, [data-bgt-button-kind=link-or] .bgt-btn .bgt-btn__text, [data-bgt-button-kind=link-or] .bgt-btn span, [data-bgt-button-kind=link-pp] .bgt-btn .bgt-btn__text, [data-bgt-button-kind=link-pp] .bgt-btn span, [data-bgt-button-kind=link-gr] .bgt-btn .bgt-btn__text, [data-bgt-button-kind=link-gr] .bgt-btn span, [data-bgt-button-kind=link-rd] .bgt-btn .bgt-btn__text, [data-bgt-button-kind=link-rd] .bgt-btn span,
.button-bl a .bgt-btn__text,
.button-bl a span, .button-or a .bgt-btn__text, .button-or a span, .button-gr a .bgt-btn__text, .button-gr a span, .button-pp a .bgt-btn__text, .button-pp a span, .button-rd a .bgt-btn__text, .button-rd a span {
  background: url(../img/common/icn_btn_bl.svg) 10px center/38px 38px no-repeat;
  min-width: 280px;
  max-width: 100%;
  padding: 18px 20px 18px 55px;
}

[data-bgt-button-kind=link-or] .bgt-btn, .button-or a {
  border-color: #F0832C;
  color: #F0832C;
}

[data-bgt-button-kind=link-or] .bgt-btn::after, .button-or a::after {
  background: linear-gradient(90deg, #f3a952 0%, #f2a050 16.96%, #f18649 45.09%, #f18549 46.32%, #f0603e 99.44%);
}

[data-bgt-button-kind=link-or] .bgt-btn .bgt-btn__text, [data-bgt-button-kind=link-or] .bgt-btn span, .button-or a .bgt-btn__text, .button-or a span {
  background: url(../img/common/icn_btn_or.svg) 10px center/38px 38px no-repeat;
}

[data-bgt-button-kind=link-pp] .bgt-btn, .button-pp a {
  border-color: #A05A94;
  color: #A05A94;
}

[data-bgt-button-kind=link-pp] .bgt-btn::after, .button-pp a::after {
  background: linear-gradient(90deg, #ca9ec7 0.12%, #c795c4 16.7%, #c17bbc 44.18%, #c079bb 46.32%, #a064a8 99.44%);
}

[data-bgt-button-kind=link-pp] .bgt-btn .bgt-btn__text, [data-bgt-button-kind=link-pp] .bgt-btn span, .button-pp a .bgt-btn__text, .button-pp a span {
  background: url(../img/common/icn_btn_pp.svg) 10px center/38px 38px no-repeat;
}

[data-bgt-button-kind=link-gr] .bgt-btn, .button-gr a {
  border-color: #82B140;
  color: #82B140;
}

[data-bgt-button-kind=link-gr] .bgt-btn::after, .button-gr a::after {
  background: linear-gradient(90deg, #bed84b 0%, #b5d84f 35.79%, #b1d851 45.67%, #9ad75c 99.44%);
}

[data-bgt-button-kind=link-gr] .bgt-btn .bgt-btn__text, [data-bgt-button-kind=link-gr] .bgt-btn span, .button-gr a .bgt-btn__text, .button-gr a span {
  background: url(../img/common/icn_btn_gr.svg) 10px center/38px 38px no-repeat;
}

[data-bgt-button-kind=link-rd] .bgt-btn, .button-rd a {
  border-color: #E60441;
  color: #E60441;
}

[data-bgt-button-kind=link-rd] .bgt-btn::after, .button-rd a::after {
  background: linear-gradient(90deg, #e99390 0.12%, #e78a8b 17.04%, #e3707d 45.09%, #e36f7c 46.32%, #e05565 100%);
}

[data-bgt-button-kind=link-rd] .bgt-btn .bgt-btn__text, [data-bgt-button-kind=link-rd] .bgt-btn span, .button-rd a .bgt-btn__text, .button-rd a span {
  background: url(../img/common/icn_btn_rd.svg) 10px center/38px 38px no-repeat;
}

@media screen and (min-width: 761px) {
  .bgt-btn--link:active, .bgt-btn--link:focus, .bgt-btn--link:hover, [data-bgt-button-kind=link] .bgt-btn:active, [data-bgt-button-kind=link] .bgt-btn:focus, [data-bgt-button-kind=link] .bgt-btn:hover, [data-bgt-button-kind=link-or] .bgt-btn:hover, [data-bgt-button-kind=link-gr] .bgt-btn:hover, [data-bgt-button-kind=link-pp] .bgt-btn:hover, [data-bgt-button-kind=link-rd] .bgt-btn:hover,
  .button-bl a:hover, .button-or a:hover, .button-gr a:hover, .button-pp a:hover, .button-rd a:hover {
    border-color: white;
    color: white;
  }
}

@media screen and (min-width: 761px) {
  .bgt-btn--link:active::after, .bgt-btn--link:focus::after, .bgt-btn--link:hover::after, [data-bgt-button-kind=link] .bgt-btn:active::after, [data-bgt-button-kind=link] .bgt-btn:focus::after, [data-bgt-button-kind=link] .bgt-btn:hover::after, [data-bgt-button-kind=link-or] .bgt-btn:hover::after, [data-bgt-button-kind=link-gr] .bgt-btn:hover::after, [data-bgt-button-kind=link-pp] .bgt-btn:hover::after, [data-bgt-button-kind=link-rd] .bgt-btn:hover::after,
  .button-bl a:hover::after, .button-or a:hover::after, .button-gr a:hover::after, .button-pp a:hover::after, .button-rd a:hover::after {
    opacity: 1;
  }
}

.bgt-btn--link:active .bgt-btn__text, .bgt-btn--link:active span, .bgt-btn--link:focus .bgt-btn__text, .bgt-btn--link:focus span, .bgt-btn--link:hover .bgt-btn__text, .bgt-btn--link:hover span, [data-bgt-button-kind=link] .bgt-btn:active .bgt-btn__text, [data-bgt-button-kind=link] .bgt-btn:active span, [data-bgt-button-kind=link] .bgt-btn:focus .bgt-btn__text, [data-bgt-button-kind=link] .bgt-btn:focus span, [data-bgt-button-kind=link] .bgt-btn:hover .bgt-btn__text, [data-bgt-button-kind=link] .bgt-btn:hover span, [data-bgt-button-kind=link-or] .bgt-btn:hover .bgt-btn__text, [data-bgt-button-kind=link-or] .bgt-btn:hover span, [data-bgt-button-kind=link-gr] .bgt-btn:hover .bgt-btn__text, [data-bgt-button-kind=link-gr] .bgt-btn:hover span, [data-bgt-button-kind=link-pp] .bgt-btn:hover .bgt-btn__text, [data-bgt-button-kind=link-pp] .bgt-btn:hover span, [data-bgt-button-kind=link-rd] .bgt-btn:hover .bgt-btn__text, [data-bgt-button-kind=link-rd] .bgt-btn:hover span,
.button-bl a:hover .bgt-btn__text,
.button-bl a:hover span, .button-or a:hover .bgt-btn__text, .button-or a:hover span, .button-gr a:hover .bgt-btn__text, .button-gr a:hover span, .button-pp a:hover .bgt-btn__text, .button-pp a:hover span, .button-rd a:hover .bgt-btn__text, .button-rd a:hover span {
  background: url(../img/common/icn_btn_bl_h.svg) 10px center/38px 38px no-repeat;
}

@media screen and (min-width: 761px) {
  [data-bgt-button-kind=link-or] .bgt-btn:hover .bgt-btn__text, [data-bgt-button-kind=link-or] .bgt-btn:hover span, .button-or a:hover .bgt-btn__text, .button-or a:hover span {
    background: url(../img/common/icn_btn_or_h.svg) 10px center/38px 38px no-repeat;
  }
}

@media screen and (min-width: 761px) {
  [data-bgt-button-kind=link-gr] .bgt-btn:hover .bgt-btn__text, [data-bgt-button-kind=link-gr] .bgt-btn:hover span, .button-gr a:hover .bgt-btn__text, .button-gr a:hover span {
    background: url(../img/common/icn_btn_gr_h.svg) 10px center/38px 38px no-repeat;
  }
}

@media screen and (min-width: 761px) {
  [data-bgt-button-kind=link-pp] .bgt-btn:hover .bgt-btn__text, [data-bgt-button-kind=link-pp] .bgt-btn:hover span, .button-pp a:hover .bgt-btn__text, .button-pp a:hover span {
    background: url(../img/common/icn_btn_pp_h.svg) 10px center/38px 38px no-repeat;
  }
}

@media screen and (min-width: 761px) {
  [data-bgt-button-kind=link-rd] .bgt-btn:hover .bgt-btn__text, [data-bgt-button-kind=link-rd] .bgt-btn:hover span, .button-rd a:hover .bgt-btn__text, .button-rd a:hover span {
    background: url(../img/common/icn_btn_rd_h.svg) 10px center/38px 38px no-repeat;
  }
}

.-anchor_link [data-bgt-button-kind=link] .bgt-btn {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  padding: 18px 20px;
  background: none;
  background-color: white;
  border: 1px solid #0078c1;
  border-radius: 100px;
  color: #0078c1;
  font-weight: 400;
  line-height: 1.3;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .-anchor_link [data-bgt-button-kind=link] .bgt-btn {
    padding: 13px 20px;
  }
}

.-anchor_link [data-bgt-button-kind=link] .bgt-btn::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  width: 16px;
  height: 16px;
  background-size: 100% auto;
  transform: translateY(-50%) rotate(90deg);
}

@media screen and (min-width: 761px) {
  .-anchor_link [data-bgt-button-kind=link] .bgt-btn:hover {
    background-color: #0078c1;
  }
}

.-anchor_link [data-bgt-button-kind=link] .bgt-btn:hover::after {
  transform: translateY(-50%) rotate(90deg);
}

@media screen and (min-width: 761px) {
  .-anchor_link [data-bgt-button-kind=link] .bgt-btn:hover {
    color: white;
  }
}

.bgt-btn--em, [data-bgt-button-kind=em] .bgt-btn {
  position: relative;
  min-width: 280px;
  max-width: 100%;
  padding: 18px 40px;
  border: 0;
  border-radius: 0;
  background: #333;
  color: #fff;
  font-weight: 700;
  clip-path: polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px);
}

.bgt-btn--em::after, [data-bgt-button-kind=em] .bgt-btn::after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #43c1df 0%, #48b8e0 24.49%, #4fabe1 46.32%, #5793e3 99.44%);
  clip-path: polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px);
  opacity: 0;
  transition: .5s;
}

.bgt-btn--em .bgt-btn__text, [data-bgt-button-kind=em] .bgt-btn .bgt-btn__text {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 761px) {
  .bgt-btn--em:active::after, .bgt-btn--em:focus::after, .bgt-btn--em:hover::after, [data-bgt-button-kind=em] .bgt-btn:active::after, [data-bgt-button-kind=em] .bgt-btn:focus::after, [data-bgt-button-kind=em] .bgt-btn:hover::after {
    opacity: 1;
  }
}

.bgt-btn--external, [data-bgt-button-kind=external] .bgt-btn {
  color: #333;
  border-color: #ccc;
  background: #fff;
  min-width: 280px;
  max-width: 100%;
  padding: 18px 40px;
  border-radius: 0;
}

@media screen and (min-width: 761px) {
  .bgt-btn--external:active, .bgt-btn--external:focus, .bgt-btn--external:hover, [data-bgt-button-kind=external] .bgt-btn:active, [data-bgt-button-kind=external] .bgt-btn:focus, [data-bgt-button-kind=external] .bgt-btn:hover {
    background-color: #EDF6FE;
  }
}

.bgt-btn--external:after, [data-bgt-button-kind=external] .bgt-btn:after {
  font-family: FontAwesome, serif;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f08e";
  font-size: 1em;
  color: #999;
  vertical-align: middle;
}

.bgt-btn--back, [data-bgt-button-kind=back] .bgt-btn {
  color: #333;
  border-color: #ddd;
  background: #ddd;
}

@media screen and (min-width: 761px) {
  .bgt-btn--back:active, .bgt-btn--back:focus, .bgt-btn--back:hover, [data-bgt-button-kind=back] .bgt-btn:active, [data-bgt-button-kind=back] .bgt-btn:focus, [data-bgt-button-kind=back] .bgt-btn:hover {
    background: #e9e9e9;
  }
}

.bgt-btn--back span, [data-bgt-button-kind=back] .bgt-btn span {
  display: inline-block;
  vertical-align: middle;
}

.bgt-btn--back:before, [data-bgt-button-kind=back] .bgt-btn:before {
  font-family: FontAwesome, serif;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f053";
  font-size: 1em;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
}

.bgt-hr {
  margin: 0 auto;
}

[data-bgt-hr-kind=dashed] .bgt-hr {
  height: 0;
  background: none;
  border: none;
  border-bottom: 2px dashed;
}

.bgt-hr--bold, [data-bgt-hr-kind=bold] .bgt-hr {
  height: 5px;
}

.bgt-hr--narrow, [data-bgt-hr-kind=narrow] .bgt-hr {
  height: 1px;
}

.bgt-hr--short, [data-bgt-hr-kind=short] .bgt-hr {
  width: 60%;
  height: 1px;
}

.bgt-download-file__link {
  text-align: left;
  font-size: 1.08em;
  font-weight: 700;
  display: inline-block;
  padding: .7em 1em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #0078c1;
  background-color: #fff;
  color: #0078c1;
  line-height: 1;
  text-decoration: none;
}

@media screen and (min-width: 761px) {
  .bgt-download-file__link:hover {
    color: #0078c1;
  }
}

.bgt-download-file__link .bgt-link__name {
  vertical-align: middle;
}

.bgt-download-file__link .bgt-link__icon::after {
  content: '\f019' !important;
  vertical-align: middle;
}

.bgt-download-file__link .bgt-link__icon.bgt-link__icon--before {
  display: none;
}

.bgt-download-file__link .bgt-link__icon.bgt-link__icon--after {
  display: inline-block;
}

@media screen and (min-width: 761px) {
  .bgt-download-file__link:hover {
    background-color: #FFFCD7;
  }
}

.bgt-download-file__link:active, .bgt-download-file__link:focus {
  -webkit-box-shadow: 0 0 5px 1px #0570c7;
  box-shadow: 0 0 5px 1px #0570c7;
  outline: none;
}

.bgt-link__size {
  display: none;
  color: grey;
  font-size: .8em;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .bgt-google-maps-link {
    margin-top: 10px;
    padding: 1em;
    text-decoration: none;
    color: #333;
    border: 1px solid #999;
    border-radius: 3px;
    text-align: center;
  }
  .bgt-google-maps-link span {
    display: inline-block;
    vertical-align: middle;
  }
  .bgt-google-maps-link:after {
    font-family: FontAwesome, serif;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f08e";
    font-size: 1em;
    vertical-align: middle;
  }
}

[data-bgt=gallery] .bgt-gallery {
  padding-top: 54%;
}

[data-bgt=gallery] .bgt-gallery:hover [data-bgt=gallery] .bgt-gallery-ctrl {
  opacity: 1;
}

[data-bgt=gallery] .bgt-gallery__img img {
  -o-object-fit: cover;
  font-family: "object-fit:cover";
  object-fit: cover;
}

[data-bgt=gallery] .bgt-gallery__caption {
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  padding: .7em 1em;
  text-align: center;
}

[data-bgt=gallery] .bgt-gallery-ctrl {
  top: 50%;
  opacity: 0;
  -webkit-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in;
}

[data-bgt=gallery] .bgt-gallery-ctrl__next, [data-bgt=gallery] .bgt-gallery-ctrl__prev {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: -20px 0 0;
  padding: 0;
  background: none;
  border: none;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

[data-bgt=gallery] .bgt-gallery-ctrl__next:hover, [data-bgt=gallery] .bgt-gallery-ctrl__prev:hover {
  opacity: .5;
}

[data-bgt=gallery] .bgt-gallery-ctrl__next:focus, [data-bgt=gallery] .bgt-gallery-ctrl__prev:focus {
  outline: none;
}

[data-bgt=gallery] .bgt-gallery-ctrl__next span, [data-bgt=gallery] .bgt-gallery-ctrl__prev span {
  display: block;
  overflow: hidden;
  width: 0;
  height: 0;
}

[data-bgt=gallery] .bgt-gallery-ctrl__next:after, [data-bgt=gallery] .bgt-gallery-ctrl__prev:after {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  border: solid #fff;
  border-width: 5px 5px 0 0;
}

[data-bgt=gallery] .bgt-gallery-ctrl__prev {
  left: 20px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

[data-bgt=gallery] .bgt-gallery-ctrl__next {
  right: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

[data-bgt=gallery] [data-gallery-marker=thumbs] + .bgt-gallery-marker {
  margin-top: 10px;
}

[data-bgt=gallery] [data-gallery-marker=thumbs] + .bgt-gallery-marker li {
  margin: 0 5px;
  width: 60px;
  height: 60px;
}

[data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker {
  margin-top: 10px;
}

[data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker li {
  margin: 0 2px;
  width: 10px;
  height: 10px;
  background-color: #999;
  border-radius: 100%;
}

[data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker li.current {
  background-color: #000;
}

[data-bgb=image1] .bgt-box__caption {
  text-align: center;
}

.pc-only {
  display: block;
}

@media screen and (max-width: 768px) {
  .pc-only {
    display: none;
  }
}

.sp-only {
  display: none;
}

@media screen and (max-width: 768px) {
  .sp-only {
    display: block;
  }
}

.main .bge-contents [data-bgb] {
  margin: -80px 0 60px;
  padding-top: 80px;
}

@media screen and (max-width: 760px) {
  .main .bge-contents [data-bgb] {
    margin: -54px 0 30px;
    padding-top: 54px;
  }
}

@media screen and (max-width: 760px) {
  .bge-contents {
    overflow: hidden;
  }
}

.bge-contents [data-bgb].regi_block {
  margin-top: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.bge-contents [data-bgb].bg-blue {
  margin-top: 0 !important;
  padding-top: 20px !important;
}

.bge-contents [data-bgb].bg-blue > div {
  margin-bottom: 0;
  padding: 20px 30px;
  background: #E4F4FD;
}

.bge-contents [data-bgb].bg-blue > div p:last-child {
  margin-bottom: 0;
}

.bge-contents [data-bgb].bg-orange {
  margin-top: 0 !important;
  padding-top: 20px !important;
}

.bge-contents [data-bgb].bg-orange > div {
  margin-bottom: 0;
  padding: 20px 30px;
  background: #FDE7D3;
}

.bge-contents [data-bgb].bg-orange > div p:last-child {
  margin-bottom: 0;
}

.bge-contents [data-bgb].bg-green {
  margin-top: 0 !important;
  padding-top: 20px !important;
}

.bge-contents [data-bgb].bg-green > div {
  margin-bottom: 0;
  padding: 20px 30px;
  background: #EAF3DE;
}

.bge-contents [data-bgb].bg-green > div p:last-child {
  margin-bottom: 0;
}

.bge-contents [data-bgb].bg-purple {
  margin-top: 0 !important;
  padding-top: 20px !important;
}

.bge-contents [data-bgb].bg-purple > div {
  margin-bottom: 0;
  padding: 20px 30px;
  background: #EEE0EB;
}

.bge-contents [data-bgb].bg-purple > div p:last-child {
  margin-bottom: 0;
}

.bge-contents [data-bgb].bg-red > div {
  margin-top: 0 !important;
  padding-top: 20px !important;
  margin-bottom: 0;
  padding: 20px 30px;
  background: #F8E2E3;
}

.bge-contents [data-bgb].bg-red > div p:last-child {
  margin-bottom: 0;
}

.bge-contents [data-bgb].bg-gray {
  margin-top: 0 !important;
  padding-top: 20px !important;
}

.bge-contents [data-bgb].bg-gray > div {
  margin-bottom: 0;
  padding: 20px 30px;
  background: #f3f3f3;
}

.bge-contents [data-bgb].bg-gray > div p:last-child {
  margin-bottom: 0;
}

.bge-contents [data-bgb=title], .bge-contents [data-bgb=title2] {
  margin-bottom: 30px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=title], .bge-contents [data-bgb=title2] {
    margin-bottom: 15px;
  }
}

.bge-contents [data-bgb]:not(.bge-wide):not(.bge-imgwide) {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb]:not(.bge-wide):not(.bge-imgwide) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.bge-contents [data-bgb].w900 {
  max-width: 900px !important;
}

.bge-contents [data-bgb].w800 {
  max-width: 800px !important;
}

.bge-contents [data-bgb].w760 {
  max-width: 760px !important;
}

.bge-contents [data-bgb].w740 {
  max-width: 740px !important;
}

.bge-contents [data-bgb].w700 {
  max-width: 700px !important;
}

.bge-contents [data-bgb].bge-wide {
  margin-top: 0;
  padding: 50px 0;
  background: #f3f3f3;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb].bge-wide {
    padding: 25px 0;
  }
}

.bge-contents [data-bgb].bge-wide.blue {
  background: #E4F4FD;
}

.bge-contents [data-bgb].bge-wide.orange {
  background: #FDE7D3;
}

.bge-contents [data-bgb].bge-wide.green {
  background: #EAF3DE;
}

.bge-contents [data-bgb].bge-wide.purple {
  background: #EEE0EB;
}

.bge-contents [data-bgb].bge-wide.red {
  background: #F8E2E3;
}

.bge-contents [data-bgb].bge-wide [data-bgt] {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb].bge-wide [data-bgt] {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.bge-contents [data-bgb].bge-wide [data-bgt] h2 {
  margin: 0 0 60px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb].bge-wide [data-bgt] h2 {
    margin: 0 0 30px;
  }
}

.bge-contents [data-bgb].bge-spwide {
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb].bge-spwide {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.bge-contents [data-bgb].bge-imgwide img {
  width: 100%;
}

.bge-contents .bgt-box__image-container {
  text-align: center;
}

.bge-contents .bgt-ckeditor-container a {
  text-decoration: underline;
}

.bge-contents.bge_contents.bge_content .responsive_pc {
  position: relative;
  padding-bottom: 10px;
}

.bge-contents.bge_contents.bge_content .responsive_pc::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  z-index: 100;
  content: "　パソコン版　";
  width: auto;
  height: auto;
  background: #ff0;
  color: #000;
  font-size: 16px;
  line-height: 1.5;
}

.bge-contents.bge_contents.bge_content .responsive_sp {
  position: relative;
  text-align: center;
}

.bge-contents.bge_contents.bge_content .responsive_sp::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  z-index: 100;
  content: "　スマホ版　";
  width: auto;
  height: auto;
  background: #ff0;
  color: #000;
  font-size: 16px;
  line-height: 1.5;
}

.bge-contents.bge_contents.bge_content .responsive_sp img {
  width: 40%;
}

.bge-contents.bge_contents.bge_content [data-bgb].hide {
  position: relative;
  padding-top: 34px;
}

.bge-contents.bge_contents.bge_content [data-bgb].hide::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  z-index: 100;
  content: "　非表示中　";
  width: auto;
  height: auto;
  background: #ff0;
  color: #000;
  font-size: 16px;
  line-height: 1.5;
}

.wrapper .main .bge-contents .responsive_pc {
  display: block;
}

@media screen and (max-width: 760px) {
  .wrapper .main .bge-contents .responsive_pc {
    display: none;
  }
}

.wrapper .main .bge-contents .responsive_sp {
  display: none;
}

@media screen and (max-width: 760px) {
  .wrapper .main .bge-contents .responsive_sp {
    display: block;
  }
}

[data-bgb=image6] {
  display: flex;
  flex-wrap: wrap;
}

[data-bgb=image6] .bgt-grid {
  width: 15%;
  margin-right: 2%;
  margin-bottom: 20px;
}

@media screen and (max-width: 760px) {
  [data-bgb=image6] .bgt-grid {
    margin-bottom: 10px;
  }
}

[data-bgb=image6] .bgt-grid:last-child {
  margin-right: 0;
}

[data-bgb=image6] .bgt-grid .bgt-image-container {
  margin-bottom: 10px;
}

[data-bgb=image6] .bgt-grid .bgt-ckeditor-container {
  line-height: 1.3;
}

[data-bgb=image6].goods-thumb .bgt-grid {
  width: 32%;
  margin-right: 2%;
}

[data-bgb=image6].goods-thumb .bgt-grid:nth-child(3n) {
  margin-right: 0;
}

.flex-block {
  display: flex;
  flex-wrap: wrap;
}

.flex-block .flex-grid {
  width: 23%;
  margin-right: 2.6666%;
  margin-bottom: 40px;
}

@media screen and (max-width: 760px) {
  .flex-block .flex-grid {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 761px) {
  .flex-block .flex-grid:nth-child(4n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .flex-block .flex-grid:nth-child(2n) {
    margin-right: 0;
  }
}

.flex-block .flex-grid .bgt-image-container {
  margin-bottom: 10px;
}

.flex-block .flex-grid .bgt-ckeditor-container {
  line-height: 1.3;
}

[data-bgb=button2] .bgt-grid:first-child, [data-bgb=download-file2] .bgt-grid:first-child, [data-bgb=image2] .bgt-grid:first-child, [data-bgb=image-link2] .bgt-grid:first-child, [data-bgb=image-link-text2] .bgt-grid:first-child, [data-bgb=image-text2] .bgt-grid:first-child, [data-bgb=trimmed-image2] .bgt-grid:first-child, [data-bgb=trimmed-image-link2] .bgt-grid:first-child, [data-bgb=wysiwyg2] .bgt-grid:first-child {
  padding-right: 30px;
}

@media screen and (max-width: 760px) {
  [data-bgb=button2] .bgt-grid:first-child, [data-bgb=download-file2] .bgt-grid:first-child, [data-bgb=image2] .bgt-grid:first-child, [data-bgb=image-link2] .bgt-grid:first-child, [data-bgb=image-link-text2] .bgt-grid:first-child, [data-bgb=image-text2] .bgt-grid:first-child, [data-bgb=trimmed-image2] .bgt-grid:first-child, [data-bgb=trimmed-image-link2] .bgt-grid:first-child, [data-bgb=wysiwyg2] .bgt-grid:first-child {
    margin-bottom: 60px;
    padding-right: 0;
  }
}

[data-bgb=button2] .bgt-grid:nth-child(2), [data-bgb=download-file2] .bgt-grid:nth-child(2), [data-bgb=image2] .bgt-grid:nth-child(2), [data-bgb=image-link2] .bgt-grid:nth-child(2), [data-bgb=image-link-text2] .bgt-grid:nth-child(2), [data-bgb=image-text2] .bgt-grid:nth-child(2), [data-bgb=trimmed-image2] .bgt-grid:nth-child(2), [data-bgb=trimmed-image-link2] .bgt-grid:nth-child(2), [data-bgb=wysiwyg2] .bgt-grid:nth-child(2) {
  padding-left: 30px;
}

@media screen and (max-width: 760px) {
  [data-bgb=button2] .bgt-grid:nth-child(2), [data-bgb=download-file2] .bgt-grid:nth-child(2), [data-bgb=image2] .bgt-grid:nth-child(2), [data-bgb=image-link2] .bgt-grid:nth-child(2), [data-bgb=image-link-text2] .bgt-grid:nth-child(2), [data-bgb=image-text2] .bgt-grid:nth-child(2), [data-bgb=trimmed-image2] .bgt-grid:nth-child(2), [data-bgb=trimmed-image-link2] .bgt-grid:nth-child(2), [data-bgb=wysiwyg2] .bgt-grid:nth-child(2) {
    margin-bottom: 60px;
    padding-left: 0;
  }
}

@media screen and (max-width: 760px) {
  [data-bgb=text-image1] .bgt-grid--first {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 760px) {
  [data-bgb=text-image2] .bgt-grid--first {
    margin-bottom: 30px;
  }
}

[data-bgb=image-text3] .bgt-image-container {
  margin-bottom: 30px;
}

.bgb-opt--mb-none h2 {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 760px) {
  .bgb-opt--mb-none h2 {
    margin-bottom: 0 !important;
  }
}

#Recruit [data-bgb] h2.bge-title-h2 {
  border-color: #EDF6FE;
}

#Recruit [data-bgt] h3.bge-title-h3 {
  background: #0078c1;
}

.bge-pagenav_list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  justify-content: center;
}

@media screen and (max-width: 760px) {
  .bge-pagenav_list {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .bge-pagenav_list {
    border-bottom: 0;
  }
}

.bge-pagenav_list_item {
  margin-bottom: 0 !important;
  margin-right: 15px;
  list-style: none !important;
}

@media screen and (max-width: 760px) {
  .bge-pagenav_list_item {
    margin-bottom: 4px !important;
  }
}

.bge-pagenav_list_item [data-bgt=button] .bgt-btn {
  width: 100%;
  padding: 5px 0;
  border-color: #009FE8;
  border-radius: 5px;
  color: #009FE8;
  font-size: 19px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  .bge-pagenav_list_item [data-bgt=button] .bgt-btn {
    width: 100%;
    padding: 10px 0;
    border-radius: 5px;
    box-shadow: none;
    font-size: 16px;
  }
}

.bge-pagenav_list_item [data-bgt=button] .bgt-btn::after {
  border-radius: 4px;
  background: #E4F4FD;
}

.bge-pagenav_list_item [data-bgt=button] .bgt-btn .bgt-btn__text {
  min-width: auto;
  max-width: none;
  padding: 6px 18px;
  background: none;
}

@media screen and (max-width: 760px) {
  .bge-pagenav_list_item [data-bgt=button] .bgt-btn .bgt-btn__text {
    padding: 0;
    font-size: 14px;
  }
}

@media screen and (min-width: 761px) {
  .bge-pagenav_list_item [data-bgt=button] .bgt-btn:hover {
    border-color: #0078c1;
    color: white;
    box-shadow: none;
  }
}

.bge-pagenav_list_item [data-bgt=button] [data-bgt-button-kind=current] .bgt-btn {
  position: relative;
  background: #009FE8;
  color: white;
  box-shadow: none;
  pointer-events: none;
}

.bge-pagenav_list_item [data-bgt=button] [data-bgt-button-kind=current] .bgt-btn::after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  content: '';
  display: block;
  width: 17px;
  height: 10px;
  background: #009FE8;
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  transform: translateX(-50%);
}

@media screen and (max-width: 760px) {
  .bge-pagenav_list_item [data-bgt=button] [data-bgt-button-kind=current] .bgt-btn::after {
    display: none;
  }
}

.bgb-pagenavi2 .bge-pagenav_list_item {
  width: 30%;
}

@media screen and (max-width: 760px) {
  .bgb-pagenavi2 .bge-pagenav_list_item {
    width: 100%;
  }
}

.bgb-pagenavi3 .bge-pagenav_list_item {
  width: 33.33%;
}

@media screen and (max-width: 760px) {
  .bgb-pagenavi3 .bge-pagenav_list_item {
    width: 100%;
  }
}

.bgb-pagenavi4 .bge-pagenav_list_item {
  width: 25%;
}

@media screen and (max-width: 760px) {
  .bgb-pagenavi4 .bge-pagenav_list_item {
    width: 100%;
  }
}

.bgb-pagenavi5 .bge-pagenav_list_item {
  width: 20%;
}

@media screen and (max-width: 760px) {
  .bgb-pagenavi5 .bge-pagenav_list_item {
    width: 100%;
  }
}

.bgb-pagenavi .bge-pagenav_list_item {
  width: 16%;
}

@media screen and (max-width: 760px) {
  .bgb-pagenavi .bge-pagenav_list_item {
    width: 100%;
  }
}

.wrapper-home .bge-pagenav_list_item [data-bgt=button] .bgt-btn {
  border-color: #009FE8;
  color: #009FE8;
}

.wrapper-home .bge-pagenav_list_item [data-bgt=button] [data-bgt-button-kind=current] .bgt-btn {
  border-color: #009FE8;
  background: #009FE8;
  color: white;
}

.wrapper-home .bge-pagenav_list_item [data-bgt=button] .bgt-btn::after {
  background: #009FE8;
}

.wrapper-business .bge-pagenav_list_item [data-bgt=button] .bgt-btn {
  border-color: #F0832C;
  color: #F0832C;
}

.wrapper-business .bge-pagenav_list_item [data-bgt=button] [data-bgt-button-kind=current] .bgt-btn {
  border-color: #F0832C;
  background: #F0832C;
  color: white;
}

.wrapper-business .bge-pagenav_list_item [data-bgt=button] .bgt-btn::after {
  background: #F0832C;
}

.wrapper-company .bge-pagenav_list_item [data-bgt=button] .bgt-btn {
  border-color: #82B140;
  color: #82B140;
}

.wrapper-company .bge-pagenav_list_item [data-bgt=button] [data-bgt-button-kind=current] .bgt-btn {
  border-color: #82B140;
  background: #82B140;
  color: white;
}

.wrapper-company .bge-pagenav_list_item [data-bgt=button] .bgt-btn::after {
  background: #82B140;
}

.wrapper-support .bge-pagenav_list_item [data-bgt=button] .bgt-btn {
  border-color: #A05A94;
  color: #A05A94;
}

.wrapper-support .bge-pagenav_list_item [data-bgt=button] [data-bgt-button-kind=current] .bgt-btn {
  border-color: #A05A94;
  background: #A05A94;
  color: white;
}

.wrapper-support .bge-pagenav_list_item [data-bgt=button] .bgt-btn::after {
  background: #A05A94;
}

.wrapper-help .bge-pagenav_list_item [data-bgt=button] .bgt-btn {
  border-color: #E60441;
  color: #E60441;
}

.wrapper-help .bge-pagenav_list_item [data-bgt=button] [data-bgt-button-kind=current] .bgt-btn {
  border-color: #E60441;
  background: #E60441;
  color: white;
}

.wrapper-help .bge-pagenav_list_item [data-bgt=button] .bgt-btn::after {
  background: #E60441;
}
