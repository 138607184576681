.main {
  .bge-contents {
      [data-bgb] {
      margin: -80px 0 60px;
      padding-top: 80px;
      @include mq(sp) {
        margin: -54px 0 30px;
        padding-top: 54px;
      }
    }
  }
}

.bge-contents {
  //padding-bottom: 60px;
  @include mq(sp) {
    //padding-bottom: 30px;
    overflow: hidden;
  }

// 上下マージン
  [data-bgb] {
    &.regi_block {
      margin-top: 0;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &.bg-blue {
      margin-top: 0 !important;
      padding-top: 20px !important;
      & > div {
        margin-bottom: 0;
        padding: 20px 30px;
        background: $light-blue;
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.bg-orange {
      margin-top: 0 !important;
      padding-top: 20px !important;
      & > div {
        margin-bottom: 0;
        padding: 20px 30px;
        background: $light-orange;
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.bg-green {
      margin-top: 0 !important;
      padding-top: 20px !important;
      & > div {
        margin-bottom: 0;
        padding: 20px 30px;
        background: $light-green;
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.bg-purple {
      margin-top: 0 !important;
      padding-top: 20px !important;
      & > div {
        margin-bottom: 0;
        padding: 20px 30px;
        background: $light-purple;
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.bg-red {
      & > div {
        margin-top: 0 !important;
        padding-top: 20px !important;
        margin-bottom: 0;
        padding: 20px 30px;
        background: $light-red;
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.bg-gray {
      margin-top: 0 !important;
      padding-top: 20px !important;
      & > div {
        margin-bottom: 0;
        padding: 20px 30px;
        background: $light-gray;
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  [data-bgb=title],[data-bgb=title2] {
    margin-bottom: 30px;
    @include mq(sp) {
      margin-bottom: 15px;
    }
  }

// 最大幅指定
  [data-bgb]:not(.bge-wide):not(.bge-imgwide) {
    @include base(1140,20,5vw);
  }

// 幅指定
  [data-bgb].w900 {
    max-width: 900px !important;
  }
  [data-bgb].w800 {
    max-width: 800px !important;
  }
  [data-bgb].w760 {
    max-width: 760px !important;
  }
  [data-bgb].w740 {
    max-width: 740px !important;
  }
  [data-bgb].w700 {
    max-width: 700px !important;
  }

// .bge-wide 幅いっぱい背景色
  [data-bgb].bge-wide {
    margin-top: 0;
    padding: 50px 0;
    background: $light-gray;
    @include mq(sp) {
      padding: 25px 0;
    }
// .bge-wide.COLORNAME カラバリ
    &.blue {
      background: $light-blue;
    }
    &.orange {
      background: $light-orange;
    }
    &.green {
      background: $light-green;
    }
    &.purple {
      background: $light-purple;
    }
    &.red {
      background: $light-red;
    }
    [data-bgt] {
      @include base(1140,20,5vw);
      h2 {
        margin: 0 0 60px;
        @include mq(sp) {
          margin: 0 0 30px;
        }
      }
    }
  }

// 画像スマホのみ幅いっぱい
  [data-bgb].bge-spwide {
    @include base(1040,20,0);
  }
// 画像幅いっぱい
  [data-bgb].bge-imgwide {
    img {
      width: 100%;
    }
  }

// 画像下テキストをセンター寄せ
  .bgt-box__image-container {
    text-align: center;
  }
// テキストリンクにアンダーライン
  .bgt-ckeditor-container {
    a {
      text-decoration: underline;
    }
  }
}

// レスポンシブ切り替え対応
.bge-contents.bge_contents.bge_content {
  .responsive {
    &_pc {
      position: relative;
      padding-bottom: 10px;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: auto;
        z-index: 100;
        content: "　パソコン版　";
        width: auto;
        height: auto;
        background: #ff0;
        color: #000;
        font-size: 16px;
        line-height: 1.5;
      }
    }
    &_sp {
      position: relative;
      text-align: center;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: auto;
        z-index: 100;
        content: "　スマホ版　";
        width: auto;
        height: auto;
        background: #ff0;
        color: #000;
        font-size: 16px;
        line-height: 1.5;
      }
      img {
        width: 40%;
      }
    }
  }
}
.bge-contents.bge_contents.bge_content [data-bgb].hide {
  position: relative;
  padding-top: 34px;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    z-index: 100;
    content: "　非表示中　";
    width: auto;
    height: auto;
    background: #ff0;
    color: #000;
    font-size: 16px;
    line-height: 1.5;
  }
}
.wrapper .main .bge-contents {
  .responsive {
    &_pc {
      display: block;
      @include mq(sp) {
        display: none;
      }
    }
    &_sp {
      display: none;
      @include mq(sp) {
        display: block;
      }
    }
  }
}

[data-bgb=image6] {
  display: flex;
  flex-wrap: wrap;
  .bgt-grid {
    width: 15%;
    margin-right: 2%;
    margin-bottom: 20px;
    @include mq(sp) {
      margin-bottom: 10px;
    }
    &:last-child {
      margin-right: 0;
    }
    .bgt-image-container {
      margin-bottom: 10px;
    }
    .bgt-ckeditor-container {
      line-height: 1.3;
    }
  }
  &.goods-thumb {
    .bgt-grid {
      width: 32%;
      margin-right: 2%;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

.flex-block {
  display: flex;
  flex-wrap: wrap;
  .flex-grid {
    width: 23%;
    margin-right: 2.6666%;
    margin-bottom: 40px;
    @include mq(sp) {
      width: 48%;
      margin-right: 4%;
      margin-bottom: 20px;
    }
    &:nth-child(4n) {
      @include mq(pc) {
        margin-right: 0;
      }
    }
    &:nth-child(2n) {
      @include mq(sp) {
        margin-right: 0;
      }
    }
    .bgt-image-container {
      margin-bottom: 10px;
    }
    .bgt-ckeditor-container {
      line-height: 1.3;
    }
  }
}

[data-bgb=button2] .bgt-grid:first-child, [data-bgb=download-file2] .bgt-grid:first-child, [data-bgb=image2] .bgt-grid:first-child, [data-bgb=image-link2] .bgt-grid:first-child, [data-bgb=image-link-text2] .bgt-grid:first-child, [data-bgb=image-text2] .bgt-grid:first-child, [data-bgb=trimmed-image2] .bgt-grid:first-child, [data-bgb=trimmed-image-link2] .bgt-grid:first-child, [data-bgb=wysiwyg2] .bgt-grid:first-child {
    padding-right: 30px;
    @include mq(sp) {
      margin-bottom: 60px;
      padding-right: 0;
    }
}
[data-bgb=button2] .bgt-grid:nth-child(2), [data-bgb=download-file2] .bgt-grid:nth-child(2), [data-bgb=image2] .bgt-grid:nth-child(2), [data-bgb=image-link2] .bgt-grid:nth-child(2), [data-bgb=image-link-text2] .bgt-grid:nth-child(2), [data-bgb=image-text2] .bgt-grid:nth-child(2), [data-bgb=trimmed-image2] .bgt-grid:nth-child(2), [data-bgb=trimmed-image-link2] .bgt-grid:nth-child(2), [data-bgb=wysiwyg2] .bgt-grid:nth-child(2) {
    padding-left: 30px;
    @include mq(sp) {
      margin-bottom: 60px;
      padding-left: 0;
    }
}

[data-bgb=text-image1] .bgt-grid--first {
  @include mq(sp) {
    margin-bottom: 30px;
  }
}
[data-bgb=text-image2] .bgt-grid--first {
  @include mq(sp) {
    margin-bottom: 30px;
  }
}
[data-bgb=image-text3] .bgt-image-container {
  margin-bottom: 30px;
}

.bgb-opt--mb-none h2 {
  margin-bottom: 0 !important;
  @include mq(sp) {
    margin-bottom: 0 !important;
  }
}

#Recruit [data-bgb] h2.bge-title-h2 {
  border-color: $sub;
}
#Recruit [data-bgt] h3.bge-title-h3 {
  background: $main;
}

.bge-pagenav {
  &_list {
    @include flex();
    justify-content: center;
    @include mq(sp) {
      border-bottom: 0;
    }
    &_item {
      margin-bottom: 0 !important;
      margin-right: 15px;
      list-style: none !important;
      @include mq(sp) {
        margin-bottom: 4px !important;
      }
      [data-bgt=button] {
        .bgt-btn {
          width: 100%;
          padding: 5px 0;
          border-color: $blue;
          border-radius: 5px;
          color: $blue;
          font-size: 19px;
          font-weight: 700;
          @include mq(sp) {
            width: 100%;
            padding: 10px 0;
            border-radius: 5px;
            box-shadow: none;
            font-size: 16px;
          }
          &::after {
            border-radius: 4px;
            background: $light-blue;
          }
          .bgt-btn__text {
            min-width: auto;
            max-width: none;
            padding: 6px 18px;
            background: none;
            @include mq(sp) {
              padding: 0;
              font-size: 14px;
            }
          }

          &:hover{
            @include mq(pc) {
              border-color: $main;
              color: white;
              box-shadow: none;
            }
          }
        }
        [data-bgt-button-kind=current] {
          .bgt-btn {
            position: relative;
            background: $blue;
            color: white;
            box-shadow: none;
            pointer-events: none;
            &::after {
              position: absolute;
              bottom: -10px;
              left: 50%;
              content: '';
              display: block;
              width: 17px;
              height: 10px;
              background: $blue;
              clip-path: polygon(0 0, 50% 100%, 100% 0);
              transform: translateX(-50%);
              @include mq(sp) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.bgb-pagenavi2 .bge-pagenav_list_item {
  width: 30%;
  @include mq(sp) {
    width: 100%;
  }
}
.bgb-pagenavi3 .bge-pagenav_list_item {
  width: 33.33%;
  @include mq(sp) {
    width: 100%;
  }
}
.bgb-pagenavi4 .bge-pagenav_list_item {
  width: 25%;
  @include mq(sp) {
    width: 100%;
  }
}
.bgb-pagenavi5 .bge-pagenav_list_item {
  width: 20%;
  @include mq(sp) {
    width: 100%;
  }
}
.bgb-pagenavi .bge-pagenav_list_item {
  width: 16%;
  @include mq(sp) {
    width: 100%;
  }
}

.wrapper-home .bge-pagenav_list_item [data-bgt=button] .bgt-btn {
  border-color: $blue;
  color: $blue;
}
.wrapper-home .bge-pagenav_list_item [data-bgt=button] [data-bgt-button-kind=current] .bgt-btn {
  border-color: $blue;
  background: $blue;
  color: white;
}
.wrapper-home .bge-pagenav_list_item [data-bgt=button] .bgt-btn::after {
  background: $blue;
}
.wrapper-business .bge-pagenav_list_item [data-bgt=button] .bgt-btn {
  border-color: $orange;
  color: $orange;
}
.wrapper-business .bge-pagenav_list_item [data-bgt=button] [data-bgt-button-kind=current] .bgt-btn {
  border-color: $orange;
  background: $orange;
  color: white;
}
.wrapper-business .bge-pagenav_list_item [data-bgt=button] .bgt-btn::after {
  background: $orange;
}
.wrapper-company .bge-pagenav_list_item [data-bgt=button] .bgt-btn {
  border-color: $green;
  color: $green;
}
.wrapper-company .bge-pagenav_list_item [data-bgt=button] [data-bgt-button-kind=current] .bgt-btn {
  border-color: $green;
  background: $green;
  color: white;
}
.wrapper-company .bge-pagenav_list_item [data-bgt=button] .bgt-btn::after {
  background: $green;
}
.wrapper-support .bge-pagenav_list_item [data-bgt=button] .bgt-btn {
  border-color: $purple;
  color: $purple;
}
.wrapper-support .bge-pagenav_list_item [data-bgt=button] [data-bgt-button-kind=current] .bgt-btn {
  border-color: $purple;
  background: $purple;
  color: white;
}
.wrapper-support .bge-pagenav_list_item [data-bgt=button] .bgt-btn::after {
  background: $purple;
}
.wrapper-help .bge-pagenav_list_item [data-bgt=button] .bgt-btn {
  border-color: $red;
  color: $red;
}
.wrapper-help .bge-pagenav_list_item [data-bgt=button] [data-bgt-button-kind=current] .bgt-btn {
  border-color: $red;
  background: $red;
  color: white;
}
.wrapper-help .bge-pagenav_list_item [data-bgt=button] .bgt-btn::after {
  background: $red;
}