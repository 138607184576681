$mediumRange: 1200px;
$gnavRange: 1100px;
$smallRange: 760px;
$tinyRange: 321px;
$navWidth: 960px;
@mixin mq($media) {
	@if $media == pc {
		@media screen and (min-width: #{$smallRange + 1}) {
			@content;
		}
	}
	@if $media == wide {
		@media screen and (min-width: #{$navWidth + 1}) {
			@content;
		}
	}
	@if $media == nav {
		@media screen and (min-width: #{$smallRange + 1}) and (max-width: #{$mediumRange}) {
			@content;
		}
	}
	@if $media == gnav {
		@media screen and (min-width: #{$smallRange + 1}) and (max-width: #{$gnavRange}) {
			@content;
		}
	}
	@if $media == sp {
		@media screen and (max-width: #{$smallRange}) {
			@content;
		}
	}
	@if $media == tiny {
		@media screen and (max-width: #{$tinyRange}) {
			@content;
		}
	}
	@if $media == mid {
		@media screen and (max-width: #{$largeRange}) {
			@content;
		}
	}
}
